<template>
    <section class="charts">
            <vue-highcharts :key="'barchart-'+keyChart" :options="options" :highcharts="Highcharts"></vue-highcharts>

    </section>
</template>
<script>
    import VueHighcharts from "vue2-highcharts";
    import Highcharts3D from "highcharts/highcharts-3d";
    import Highcharts from "highcharts";

    Highcharts3D(Highcharts);

    export default {
        components: {
            VueHighcharts
        },
        props: {
            dataBarChart: Object,
            keyChart: Number
        },
        data() {
            return {
                options: {
                    spacingBottom: 15,
                    spacingTop: 10,
                    spacingLeft: 10,
                    spacingRight: 10,
                    chart: {
                        type: "spline",
                        height: "400rem",
                    },

                    subtitle: {
                        text: ""
                    },
                    xAxis: {},
                    yAxis: {
                        min: 0,
                        title: {
                            text: "",
                            align: "high"
                        },
                        labels: {
                            overflow: "justify"
                        }
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true
                    },
                    plotOptions: {
                        spline: {
                            marker: {
                                radius: 4,
                                lineColor: "#666666",
                                lineWidth: 1
                            }
                        }
                    },
                    series: [],
                    title:"Bar Chart"
                },
                Highcharts,
            };
        },
        created() {
            this.getBarChartData();
        },
        methods: {
            getBarChartData() {
                this.options.title = this.dataBarChart.title || "Bar Chart"
                this.options.xAxis = this.dataBarChart.xAxisData || {}
                this.options.series = [
                    {
                        pointWidth: 25,
                        name:" ",
                        data: this.dataBarChart.data
                    }
                ]
            },
        }
    };
</script>
<style>
    .highcharts-credits {
        display: none;
    }

</style>
