<template>
  <div></div>
</template>

<script>
export default {
  name: "NavBarLogin",
  props: {
    source: String
  },
  computed: {},
  data: () => ({}),
  method() {}
};
</script>
<style scoped>
</style>