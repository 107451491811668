<template>
    <v-row class="content-padding login-header" no-gutters>
        <v-col cols="2" md="1">

               <v-img class="responsive-image" onclick="location.reload(true);" style="cursor: pointer" src="@/assets/logo.jpg" aspect-ratio="1" contain center></v-img>
        </v-col>
        <v-col cols="8" md="3">
            <div id="header"
                 class="item-vertical-center text-left  text-center text-sm-center text-lg-left pl-1 mt-5">
                <h5 id="GON">{{ $t("office_header.GON") }}</h5>
                <h5 id="full_name">{{ $t("office_header.full_name") }}</h5>
                <!--<h4 id="address">Singhadurbar, Kathmandu</h4>-->
                <h5>{{ $t("office_header.address") }}</h5>
            </div>
        </v-col>
        <v-col cols="12" md="8">
            <v-row class="pt-2">
                <v-col class="col-section" md="8" cols="12">
                    <p class="text-center top-section">
                        <span class="headline font-weight-bold responsive-headline">
                            {{ $t("moctca_project_detail.project_name") }}
                        </span> <br />
                        <span class="headline font-weight-black responsive-headline">
                            (CeTMIS)
                        </span>
                    </p>
                </v-col>
                <v-col md="4" cols="12" class="mb-2">
                    <v-row class="mt-3">
                        <div class="d-flex justify-space-between align-end responsive-top-icon mr-2">
                            <a href="https://play.google.com/store/apps/details?id=com.pas.tmis" target="_blank">
                                <img src="@/assets/play-store.png" alt="Play Store" style="width:115px">
                            </a>
                        </div>
                        <div class="d-flex justify-space-between align-end responsive-top-icon">
                            <a href="https://apps.apple.com/np/app/ce-tmis-homestay/id1619680409" target="_blank">
                                <img src="@/assets/app-store.png" alt="Play Store" style="width:115px">
                            </a>
                        </div>
                    </v-row>
                    <div class="d-flex justify-space-between align-end responsive-top-icon">

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon @click="showPendingUserList" class="ml-2 mt-16" v-bind="attrs"
                                        v-on="on">mdi-clock-alert</v-icon>
                            </template>
                            <span>{{$t('navBarLogin.pending_user_list')}}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon @click="showInquiryForm" class="ml-2 mt-16" v-bind="attrs"
                                        v-on="on">mdi-book-open-page-variant</v-icon>


                            </template>
                            <span>{{$t('navBarLogin.inquiry')}}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon @click="showReports" class="ml-2 mt-16" v-bind="attrs"
                                        v-on="on">mdi-chart-bar</v-icon>

                            </template>
                            <span>{{$t('navBarLogin.reports')}}</span>
                        </v-tooltip>

                        <div>

                            <div class="d-none d-sm-flex justify-end mr-12">
                                <img src="@/assets/nepal-flag.gif"
                                     aspect-ratio="1"
                                     contain
                                     center
                                     height="80" />
                            </div>

                            <div md="1" cols="2" class="d-flex justify-end ">




                                <v-btn tile
                                       rounded
                                       color="primary"
                                       class="login-btn mr-2"
                                       dark @click.stop="addModal"> {{ $t("login") }} <v-icon class="ml-2">mdi-account-circle</v-icon></v-btn>

                            </div>

                        </div>
                    </div>
                </v-col>


            </v-row>
        </v-col>

        <v-dialog v-model="dialog.download" persistent max-width="1000px">
            <download v-if="dialog.download" @formResponse="onResponse" />
        </v-dialog>

        <v-dialog v-model="snackbar.formModal"
                  transition="dialog-bottom-transition"
                  max-width="400">
            <v-card>
                <signIn @dialogueClose="dialogueClose"
                        @formResponse="onResponse"
                        v-if="snackbar.createFormComponent"></signIn>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.snackbar"
                    color="success"
                    :timeout="callbackResponse.timeout"
                    top>
            {{ callbackResponse.message }}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="signupDialog"
                  fullscreen
                  transition="dialog-bottom-transition"
                  persistent>
            <signup v-if="signupDialog" @formResponse="onResponse" />
        </v-dialog>

        <v-dialog v-model="snackbar.dialogueForData"
                  transition="dialog-bottom-transition"
                  :max-width="snackbar.report ? '890' : 690"
                  scrollable
                  persistent
                  hide-overlay>
            <v-dialog v-model="snackbar.pendingUserList"
                      persistent
                      max-width="1000px">
                <pendingUserList @dialogueClose="dialogueClose"
                                 v-if="snackbar.pendingUserList"
                                 :isSuperAdmin="false"
                                 :pending-u-r-l="snackbar.pendingURL">
                </pendingUserList>
            </v-dialog>
            <v-dialog v-model="snackbar.inquiryForm" persistent max-width="1000px">
                <inquiryForm @dialogueClose="dialogueClose" v-if="snackbar.inquiryForm">
                </inquiryForm>
            </v-dialog>

            <v-dialog v-model="snackbar.report" persistent max-width="1250px">
                <report-component @dialogueClose="dialogueClose" v-if="snackbar.report">
                </report-component>
            </v-dialog>
        </v-dialog>
    </v-row>
</template>

<script>
import Signin from "../public_pages/Signin";
import signup from "../public_pages/Signup";
import pendingUserList from "./PendingUserList";
import inquiryForm from "./InquiryForm";
import publicAxios from "../simple-axios";
import reportComponent from "./Reports/ReportsMenu";
import download from "../modules/dashboard/modal/download";

export default {
  name: "NavBarLogin",
  data() {
    //const locale = localStorage.getItem('lang') === 'en' ;
    return {
      dialog: {
        download: false,
      },
      drawer: null,
      timestamp: "",
      signupDialog: false,
      callbackResponse: {
        timeout: 6000,
      },
      // lang: true,
      snackbar: {
        color: "",
        snackbar: false,
        text: "",
        timeout: 6000,
        isLoading: false,
        formModal: false,
        createFormComponent: false,
        editFormComponent: false,
        dialogDelete: false,
        dialogueForData: false,
        pendingUserList: false,
        inquiryForm: false,
        report: false,
        pendingURL: "",
      },
    };
  },
  computed: {},
  components: {
    signIn: Signin,
    download,
    signup,
    pendingUserList,
    inquiryForm,
    reportComponent,
  },
  created() {
    // if (!this.$i18n.locale) {
    //   localStorage.setItem("lang", this.$i18n.locale);
    //   this.lang = this.$i18n.locale == "en" ? true : false;
    // } else {
    //   this.$i18n.locale = this.lang ? "en" : "ne";
    //   localStorage.setItem("lang", this.$i18n.locale);
    // }
    setInterval(this.getNow, 1000);
  },
  methods: {
    downloadModalPopup() {
      this.dialog.download = true;
    },
    goToHome() {
      console.log("roman");
      this.$router.push("/");
    },
    async onDownload() {
      await publicAxios({
        url: "Upload/DownloadManual",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "manual.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    onResponse(data) {
      if (data.message) {
        this.signupDialog = false;
        this.callbackResponse = data;
        this.snackbar.snackbar = true;
      } else {
        this.signupDialog = false;
      }
      this.dialog.download = false;
    },
    signup() {
      this.signupDialog = true;
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.timestamp = time + "     " + date;
    },
    addModal() {
      this.snackbar.formModal = true;
      this.snackbar.createFormComponent = true;
    },
    dialogueClose(data) {
      if (data) {
        console.log("ok");
        this.snackbar.snackbar = true;
        this.callbackResponse.message =
          "Inquiry message submitted successfully ";
      }
      this.snackbar.formModal = false;
      this.snackbar.createFormComponent = false;
      this.snackbar.dialogueForData = false;
      this.snackbar.pendingUserList = false;
      this.snackbar.inquiryForm = false;
      this.snackbar.report = false;
    },
    showPendingUserList() {
      this.snackbar.dialogueForData = true;
      this.snackbar.pendingUserList = true;
      this.snackbar.pendingURL = "Branch/GetPendingBranchUserRequest";
      console.log("this.dialogueForData", this.snackbar.dialogueForData);
    },
    showReports() {
      this.snackbar.dialogueForData = true;
      this.snackbar.report = true;
    },
    showInquiryForm() {
      this.snackbar.dialogueForData = true;
      this.snackbar.inquiryForm = true;
      console.log("this.dialogueForData", this.snackbar.dialogueForData);
    },
    // langChange() {
    //   const locale = this.lang ? "en" : "ne";
    //   localStorage.setItem("lang", locale);
    //   this.$i18n.locale = locale;
    // },
  },
  watch: {
    // lang: {
    //   handler() {
    //     this.langChange();
    //   },
    //   immediate: false,
    // },
  },
};
</script>
<style scoped lang="scss">
$deep-blue: #1e3470;
$speed3: cubic-bezier(0.26, 0.48, 0.08, 0.9);
.reponsive-image {
  svg {
    width: 100%;
  }
}

    .download-button {
        width: 25px;
        height: 20px;
    }
.chipContainer {
  background-color: #eeeeee86;
  padding: 2px;

  ::v-deep .v-chip-group .v-slide-group__content {
    padding: 0;
  }
}

.flex-container {
  display: flex;
  /* grid-template-columns:  repeat(auto-fit, minmax(100px, 1fr));*/
  flex-flow: row-reverse;
  padding: 3px;

  .v-btn:not(.v-btn--round).v-size--default {
    height: 24px;
    font-size: 0.65rem;
  }
}
#headerMenu {
  background-color: #077dfa;
}
// Size var
// Update this value to change the language switcher size
$height: 24px;
$width: 22px;
.button_holder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
}

.custom-avatar {
  ::v-deep.v-avatar {
    border-radius: 0;
  }
}

#GON {
  font-size: 16px;
  color: #e31215;
}

#full_name {
  color: #077dfa;
  font-size: 16px;
}
#address {
  font-size: 14px;
  color: #808080;
}
#fullform {
  font-family: cursive;
  font-size: 25px;
  color: #e31215;
}
#electronic {
  color: #077dfa;
}
    .login-btn {
        border-radius: 6px;
    }
    .other-btn {
        border-radius: 3px;

        &:hover{
            color:silver;
        }
    }

    @media screen and (max-width: 1903px) {
        #header {
            margin-left: 30px;
        }
    }

@media screen and (max-width: 1263px) {
  #header {
    margin-left: 50px;
  }
  #GON {
    font-size: 14px;
  }
  #full_name {
    font-size: 14px;
  }
  #address {
    font-size: 12px;
  }
}
    .menu-font {
        font-weight: 600;
        font-size: 1rem;

        &:hover {
            color: red;
        }
    }
    .content-padding {
        padding: 0;
    }
    #dropdown_menu {
        background-color: #0D47A1;

        ::v-deep .v-list-item {
            &:hover {
                cursor: pointer;
                background-color: #1565C0;
            }
        }

        ::v-deep .v-list-item__title {
            color: white !important;
        }
    }

    @media screen and (max-width: 700px){
        #GPN{
            font-size: 12px;
        }
        .responsive-headline,#full_name {
            font-size: 11px !important;
        }
        .responsive-image {
            height: 100px !important;
            width: 100px !important;
        }
        .top-section {
            background-color: #80808054;
            margin:0;
        }
        .col-section{
           padding:0;
        }
        .search-section{
          padding: 0px !important;
        }
        .responsive-top-icon{
          ::v-deep button{
            margin: 0px 10px!important;
          }
      }
    }
</style>
