<template>
    <v-app>
        <NavBarLogin></NavBarLogin>
        <LoginNavHeader></LoginNavHeader>
        <div id="app_bar">
            <v-row class="app-bar-menu  responsive-mp0 ps-16 mt-0">
                <v-col class="white--text province-style text-center province-font" :ref="'province.value'" v-for="province in provinces" :key="province.value" v-bind:class="{ 'active-province' : province.id===selectedProvince }" @click="selectedProvince=province.id,provinceChange(province.id)"> {{ province.value }}</v-col>
                <v-col class="d-flex justify-end mr-8">
                    <language @changeLang="languageCallback()" />
                </v-col>
            </v-row>
            <div class="content-padding">
                <SearchSection class="search-section" @searchLoading="searchLoading"
                               @resultLoaded="resultLoaded"></SearchSection>
            </div>

            <div id="carousel" class="content-padding pb-4 pt-8 px-16 responsive-mp-none">
                <v-carousel hide-delimiters :show-arrows="divisions.length >0" v-model="model" height="230">
                    <v-carousel-item v-for="(divisionDatas,index) in divisions" :key="'divisionArray'+index">
                        <v-row class="pl-16 ml-8 responsive-mp-none">
                            <v-col cols="6" md="2" v-for="division in divisionDatas" :key="'divisionData'+division.divisionName">
                                <div class="hidden-section" v-bind:class="{ 'show-hidden-section' : division.divisionName === activeSection }">

                                    <div class=" d-flex justify-start ml-14 headline white--text font-weight-black count">
                                        {{division.count}}
                                    </div>

                                    <div class="vl ml-15"></div>
                                </div>
                                <div class="carousel-background d-flex justify-center" @mouseover="activeSection = division.divisionName"
                                     @mouseleave="activeSection = ''">
                                    <div class="pt-4  text-center" @click="divisionID=division.divisionID, loadBranchList()">
                                        <v-icon class="icon">{{division.icon}}</v-icon>
                                        <p>{{division.name}}</p>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
                <div v-if="searchResult.length > 0">
                    <vue-good-table mode="remote"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange"
                                    :totalRows="totalRecords"
                                    :pagination-options="{ enabled: true,perPageDropdown: [5,10,20,50]  }"
                                    :rows="searchResult"
                                    :columns="columns">
                        <div slot="table-actions">
                            <v-icon @click="searchResult=[]">mdi-close</v-icon>
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'actions'">
                                <v-chip small
                                        class="ml-2"
                                        color="green"
                                        text-color="white"
                                        @click="loadServices(props.row)"
                                        v-if="hasServicesButton">
                                    <span v-if="hasExchangeRate"> {{$t('table.exchange_rate')}}</span>
                                    <span v-else-if="hasPeaks"> {{$t('table.peaks')}}</span>
                                    <span v-else>{{$t('table.services')}}</span>

                                </v-chip>
                                <v-chip small
                                        class="ml-2 darken-2"
                                        color="red"
                                        text-color="white"
                                        @click="loadDetails(props.row)">
                                    {{$t('table.details')}}
                                </v-chip>
                            </span>
                        </template>
                    </vue-good-table>
                    <v-dialog v-model="snackbar.modal"
                              transition="dialog-bottom-transition"
                              max-width="690"
                              scrollable
                              persistent>

                        <BranchServices @dialogueClose="dialogueClose"
                                        :divisionName="branchData.divisionName"
                                        :branchID="branchData.branchID"
                                        v-if="snackbar.branchServices"></BranchServices>
                        <BranchDetails @dialogueClose="dialogueClose"
                                       :divisionName="branchData.divisionName"
                                       :branchID="branchData.branchID"
                                       v-if="snackbar.branchDetails"></BranchDetails>
                    </v-dialog>




                </div>

            </div>

            <div id="notice">
                <div>
                    <NewsTicker :newsTickerData="newsTickerData"></NewsTicker>
                </div>
            </div>
        </div>


        <div class="statistics content-padding">
            <div class="header d-flex justify-center">
                <h2> {{$t('statistics')}} ({{serverParams.todayDate}}) </h2>
            </div>
                <div class="custom-design">
                    <v-row class="responsive-slider">
                        <v-col class="ml-4 ">
                            <div class="first" ref="myBtn" @click="getTotalArrivalDepartureByCountryData" v-bind:class="{ 'chart-active' : chartTitle === 'TOP 5 COUNTRIES ON ARRIVAL / DEPARTURE' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('arrival_departure')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="first" @click="getHotelData" v-bind:class="{ 'chart-active' : chartTitle === 'Hotel' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('hotel')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="first" @click="getHomeStayData" v-bind:class="{ 'chart-active' : chartTitle === 'Home Stay' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('homestay')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="first" @click="getPilgrimageData" v-bind:class="{ 'chart-active' : chartTitle === 'Pilgrimage' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('pilgrimage')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="first" @click="getNationalParkData" v-bind:class="{ 'chart-active' : chartTitle === 'National Park' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('national_park')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="first" @click="getMuseumsData" v-bind:class="{ 'chart-active' : chartTitle === 'Museums' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('museums')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                        <v-col>
                            <div class="first" @click="getNrbData" v-bind:class="{ 'chart-active' : chartTitle === 'NRB' }">
                                <div class="second">
                                    <div class="third ">
                                        <div class="d-flex justify-center">
                                            <span class="statistics-font">
                                                {{$t('nrb')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                    </v-row>

                    <div id="chart-container" class="chart-height ml-4">
                        <v-row class="justify-center mt-16" v-if="showChart">

                            <template v-if="chartTitle=='TOP 5 COUNTRIES ON ARRIVAL / DEPARTURE'">
                                <v-col cols="12" md="4" sm="4" lg="3" class="right-border">
                                    <MultipleBarChart :keyChart="keyChart" :data-bar-chart="addtionalMultipleBarChartData"></MultipleBarChart>
                                </v-col>


                                <v-col cols="12" md="4" sm="4" lg="3" class="right-border">
                                    <BarChart :keyChart="keyChart" :data-bar-chart="totalArrivalData"></BarChart>
                                </v-col>
                                <v-col cols="12" md="4" sm="4" lg="3">

                                    <BarChart :keyChart="keyChart" :data-bar-chart="totalDepartureData"></BarChart>
                                </v-col>
                            </template>

                            <v-col cols="12" md="4" sm="4" lg="3" class="right-border" v-else-if="['Pilgrimage','National Park','Museums'].includes(chartTitle)">
                                <BarChart :keyChart="keyChart" :data-bar-chart="totalKeyValueDatas"></BarChart>
                            </v-col>
                            <v-col cols="12" md="4" sm="4" lg="3" class="right-border" v-else-if="!['NRB'].includes(chartTitle)">
                                <BarChart :keyChart="keyChart" :data-bar-chart="addtionalData"></BarChart>
                            </v-col>
                            <v-col cols="12" md="4" sm="4" lg="3" v-if="!['TOP 5 COUNTRIES ON ARRIVAL / DEPARTURE','NRB'].includes(chartTitle)">
                                <PieChart :keyChart="keyChart" :data-bar-chart="addtionalData"></PieChart>
                            </v-col>
                            <v-col cols="12" md="4" sm="4" lg="3" v-if="!['TOP 5 COUNTRIES ON ARRIVAL / DEPARTURE'].includes(chartTitle)">
                                <LineChart :keyChart="keyChart" :data-bar-chart="addtionalData"></LineChart>
                            </v-col>
                        </v-row>

                    </div>
                </div>




        </div>

        <div id="footer" class="content-padding">
            <div class="pa-4 row justify-space-between">
                <div class="col-md-4 col-sm-6 col-12">
                    <h2 class="white--text">{{$t('about_cetmis')}}</h2>
                    <v-divider dark></v-divider>
                    <p class="aboutCetmis">
                        {{$t('moctca_project_detail.description')}}
                    </p>
                    <v-divider dark></v-divider>

                    <h4 class="gov white--text mt-3">&copy; {{serverParams.todayDate}}</h4>
                    
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <h3 class="white--text">{{$t('Support')}}</h3>
                    <v-divider dark></v-divider>
                    <div class="white--text">
                        <v-icon size="16px" class="mr-3 " color="blue darken-4">mdi-account-circle</v-icon>
                        {{$t('research_and_statistics_sections')}}
                    </div>
                    <div class="white--text">
                        <v-icon size="16px" class="mr-3 " color="blue darken-4">mdi-comment-processing-outline</v-icon>
                        info@tourism.gov.np
                    </div>
                    <div class="white--text">
                        <v-icon size="16px" class="mr-3 " color="blue darken-4">mdi-comment-processing-outline</v-icon>
                        it@tourism.gov.np
                    </div>
                    <div class="white--text">
                        <v-icon size="16px" class="mr-3 " color="blue darken-4">mdi-comment-processing-outline</v-icon>
                        research@tourism.gov.np
                    </div>
                    <div class="white--text">
                        <v-icon flat size="16px" class="mr-3 " color="blue darken-4">mdi-phone</v-icon>
                        + 977-1-4211669
                    </div>
                    <div class="white--text">
                        <v-icon size="16px" class="mr-3 " color="blue darken-4">mdi-phone</v-icon>
                        + 977-1-4211846
                    </div>
                    <v-divider dark class="mt-2"></v-divider>
                    <h4 class="gov white--text mt-4">{{$t('office_header.GON')}}</h4>
                    <h3 class="white--text">{{$t('office_header.full_name')}}</h3>
                    <h4 class="white--text">{{$t('office_header.address')}}</h4>
                </div>
                <div class="important-link col-md-4 col-sm-6 col-12">
                    <h3 class="white--text">{{$t('important_links')}}</h3>
                    <v-divider dark></v-divider>
                    <div class="link"><a href="http://www.nepal.gov.np/" target="_blank">{{$t('nepal_government_national_portal')}}</a></div>
                    <div class="link"><a href="https://tourism.gov.np/" target="_blank">{{$t('office_header.full_name')}}</a></div>
                    <div class="link"><a href="https://mofa.gov.np/" target="_blank">{{$t('mofa')}}</a></div>
                    <div class="link">
                        <a href="https://www.npc.gov.np/en/" target="_blank">{{$t('npc')}}</a>
                    </div>
                    <div class="link"><a href="https://cbs.gov.np/" target="_blank">{{$t('cbs')}}</a></div>
                    <div class="link"><a href="http://caanepal.gov.np/" target="_blank">{{$t('caan')}}</a></div>

                </div>
            </div>
        </div>
    </v-app>

</template>
<script>

    import SearchSection from "../components/SearchSection";
    import NavBarLogin from '../components/NavBarLogin';
    import LoginNavHeader from "../components/LoginNavHeader";
    import language from "@/components/Language";
    import publicAxios from "../simple-axios";
    import BranchServices from "../components/servicesProvinces/BranchServices";
    import BranchDetails from "../components/servicesProvinces/BranchDetails";
    import BarChart from "../components/VueHighCharts/BarChart";
    import PieChart from "../components/VueHighCharts/PieChart";
    import MultipleBarChart from "../components/VueHighCharts/MultipleBarChart";
    import LineChart from "../components/VueHighCharts/LineChart";
    import NewsTicker from "../components/NewsTicker";
    import "../assets/styles/signinViewsResponsive.scss";
    import axios from "axios";
    import { startCase } from 'lodash';
    export default {
        components: {
            SearchSection,
            NavBarLogin,
            LoginNavHeader,
            language,
            BranchServices,
            BranchDetails,
            BarChart,
            PieChart,
            MultipleBarChart,
            LineChart,
            NewsTicker
        },
        computed: {
            hasServicesButton() {
                return this.divisionName !== "Airport" && this.divisionName !== "Tourist-Arrival"
            },
            hasExchangeRate() {
                return this.divisionName === "Income-Generation" || this.divisionName === "MoneyExchange"
            },
            hasPeaks() {
                return this.divisionName === "Mountaineering"
            },
        },
        data() {
            return {
                snackbar: {
                    modal: false,
                    branchServices: false,
                    branchDetails: false
                },
                model: 0,
                provinces: [],
                divisions: [],
                searchOptions: [],
                totalArrivalData: {},
                totalDepartureData: {},
                totalNationalParkData: {},
                hotelData: {},
                homeStayData: {},
                totalPilgrimageData: {},
                colors: [
                    'primary',
                    'secondary',
                    'yellow darken-2',
                    'red',
                    'orange',
                ],
                radius: 10,
                padding: 8,
                lineCap: 'round',
                value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
                fill: false,
                autoLineWidth: false,

                drawer: null,
                timestamp: "",
                searchForData: false,
                activeSection: "",
                columns: [
                    {
                        label: this.$t('table.brand_name'),
                        field: "branchName"
                    },
                    {
                        label: this.$t('table.address'),
                        field: "branchAddress"
                    },
                    {
                        label: this.$t('phone'),
                        field: "branchPhoneNumber"
                    },
                    {
                        label: this.$t('table.website'),
                        field: "branchWebsite"
                    },
                    {
                        label: this.$t('table.actions'),
                        field: "actions",
                        sortable: false
                    }
                ],
                searchResult: [],
                divisionID: null,
                totalRecords: 0,
                search: null,
                isLoading: false,
                loading: false,
                branchData: [],
                serverParams: {
                    search: "",
                    todayDate: null,
                    columnFilters: {},
                    sort: [
                        {
                            field: "branchID",
                            type: "desc"
                        }
                    ],
                    pageNo: 1,
                    pageSize: 5
                },
                addtionalData: {

                },
                addtionalMultipleBarChartData: {},
                showChart: false,
                chartTitle: "Arrival/Departure",
                title: null,
                selectedProvince: 1,
                keyChart: 0,
                totalKeyValueDatas: {},
                newsTickerData: {},
                currentProvinceId: null,

            }
        },
        methods: {
            async languageCallback() {
                this.getProvince();
                this.loadDivisions(this.currentProvinceId);
                let noticeNepali = localStorage.getItem('lang');
                if (noticeNepali === 'ne') {
                    const noticeRequest = await axios.get("Report/GetNoticeNEAsync");
                    this.newsTickerData = noticeRequest.data;
                }
                else {
                    const noticeRequest = await axios.get("Report/GetNoticeAsync");
                    this.newsTickerData = noticeRequest.data;
                }

            },
            loadDetails({ divisionName, branchID }) {
                this.snackbar.modal = true
                this.snackbar.branchDetails = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                this.serverParams.todayDate = date;
            },
            loadServices({ divisionName, branchID }) {
                this.snackbar.modal = true
                this.snackbar.branchServices = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                console.log(this.branchData.divisionName, this.branchData.branchID)
            },
            async getProvince() {
                let provNepali = localStorage.getItem('lang');
                if (provNepali === 'ne') {
                    const provinceRequest = await axios.get("Province/DDLProvinceListNE");
                    this.provinces = provinceRequest.data;
                }
                else {
                    const provinceRequest = await axios.get("Province/DDLProvinceList");
                    this.provinces = provinceRequest.data;

                }
            },
            searchLoading() {
                this.searchForData = true
            },
            resultLoaded() {

            },
            provinceChange(provinceID) {
                this.loadDivisions(provinceID)
            },

            async loadDivisions(provinceID) {
              this.currentProvinceId = provinceID;
                let divNepali = localStorage.getItem('lang');
                if (divNepali === 'ne') {
                    const divisions = await publicAxios.post('PublicDetail/GetDivisionListNewNEAsync', {
                        provinceID
                    })
                    let mapData = await divisions.data.map(res => { return { ...res, 'name': res.divisionName.replaceAll('-', '').split(/(?=[A-Z])/).join(" ") } })
                    if (this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs') {
                        this.divisions = this.chunks(mapData, 2)
                    } else {
                        this.divisions = this.chunks(mapData, 6)
                    }

                }
                else {
                    const divisions = await publicAxios.post('PublicDetail/GetDivisionListNewAsync', {
                        provinceID
                    })
                    let mapData = await divisions.data.map(res => { return { ...res, 'name': res.divisionName.replaceAll('-', '').split(/(?=[A-Z])/).join(" ") } })
                    if (this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs') {
                        this.divisions = this.chunks(mapData, 2)
                    } else {
                        this.divisions = this.chunks(mapData, 6)

                    }
                }

            },
            async loadBranchList() {
                const { data } = await publicAxios.post('PublicDetail/BranchListAsync', {
                    divisionID: this.divisionID,
                    ...this.serverParams
                })
                this.searchResult = data.data
                this.totalRecords = data.totalCount
            },
            chunks(arr, size) {
                return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
                    arr.slice(i * size, i * size + size)
                );
            },






            generateChartDataNew(data, title) {
                this.keyChart++;
                const chartRow = Object.values(data)
                const chartColumn = Object.keys(data)
                const chartColumns = chartColumn.map(res => startCase(res))
              const pieData = Object.entries(data).map(keyData => {
                return [startCase(keyData[0]), keyData[1]]
              })
                let addtionalData = {
                    title: {
                        text: title,
                        style: {
                            color: '#1f5fc3',
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    xAxisData: {
                        categories: chartColumns,
                        title: {
                            text: ""
                        },


                    },
                    data: chartRow,
                    pieData
                }
                return addtionalData
            },
            generateChartData(data, title) {
                this.keyChart++;
                const chartRow = Object.values(data)
                const chartColumn = Object.keys(data)
                const chartColumns = chartColumn.map(res => startCase(res))
                const pieData = Object.entries(data).map(keyData => {
                    return [startCase(keyData[0]), keyData[1]]
                })
                let addtionalData = {
                    title: {
                        text: startCase(title),
                        style: {
                            color: '#1f5fc3',
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    xAxisData: {
                        categories: chartColumns,
                        title: {
                            text: ""
                        }
                    },
                    data: chartRow,
                    pieData
                }
                return addtionalData
            },
            generteChartKeyValueData(data, title) {
                this.keyChart++
                let names = []
                let values = []
                data.map(res => {
                    names.push(res.name);
                    values.push(res.value);

                })
                let addtionalMultipleBarChartData = {
                    title: {
                        text: title,
                        style: {
                            color: '#1f5fc3',
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    xAxisData: {
                        categories: names,
                        title: {
                            text: ""
                        }
                    },
                    data: values
                }
                return addtionalMultipleBarChartData
            },

            generteChartMultipleData(data) {
                this.keyChart++
                let chartColumn = []
                let arrival = []
                let departure = []
                data.map(res => {
                    chartColumn.push(res.country);
                    arrival.push(res.arrival);
                    departure.push(res.departure);

                })
                this.addtionalMultipleBarChartData = {
                    title: {
                        text: this.chartTitle,
                        style: {
                            color:'#1f5fc3',
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    xAxisData: {
                        categories: chartColumn,
                        title: {
                            text: ""
                        }
                    },
                    data: [
                        {
                            name: "Arrival",
                            data:arrival
                        },
                        {
                            name: "Departure",
                            data: departure
                        }
                    ]
                }
            },
            async getTotalArrivalData() {
                const { data } = await publicAxios.get('Immigration/GetArrivalDepartureByGender?Type=Arrival')
                this.totalArrivalData = await this.generateChartDataNew(data,"TOURIST ARRIVAL BY SEX / POE")
            },
            async getTotalDepartureData() {
                const { data } = await publicAxios.get('Immigration/GetArrivalDepartureByGender?Type=Departure')
                this.totalDepartureData = await this.generateChartDataNew(data,"TOURIST DEPARTURE BY SEX / POE")

            },
            async getPilgrimageData() {
                this.showChart = false
                this.chartTitle = "Pilgrimage"
                const { data } = await publicAxios.get('Pilgrimage/GetPilgrimageData')
                this.addtionalData = this.generateChartData(data, "GENDER WISE VISITORS IN PILGRIMAGE")
                await this.getTopArrivalCountryInPilgrimageData();
                this.showChart = true

            },
            async getNationalParkData() {
                this.showChart = false
                this.chartTitle = "National Park"
                const { data } = await publicAxios.get('NationalPark/GetNationalParkData')
                this.addtionalData = this.generateChartData(data, "GENDER WISE VISITORS IN NATIONAL PARK")
                await this.getTopArrivalCountryInNationalParkData();
                this.showChart = true
            },
            async getMuseumsData() {
                this.showChart = false
                this.chartTitle = "Museums"
                const { data } = await publicAxios.get('Museum/GetMuseumsData')
                this.addtionalData = this.generateChartData(data, "GENDER WISE VISITORS IN MUSEUMS")
                await this.getTopArrivalCountryInMuseumData();
                this.showChart = true
            },
            async getNrbData() {
                this.showChart = false
                this.chartTitle = "NRB"
                const { data } = await publicAxios.get('NRBExchange/GetNrbGraphAsync', {})
                let modifiedKeyData = data.map(res => ({ name: res.fiscalYear, value: res.amount}))
                this.addtionalData = this.generteChartKeyValueData(modifiedKeyData, "Income Generation")
                this.showChart = true
            },
            async getTotalArrivalDepartureByCountryData() {
                this.showChart = false

                this.getTotalArrivalData();
                this.getTotalDepartureData();
                this.chartTitle = "TOP 5 COUNTRIES ON ARRIVAL / DEPARTURE"
                const { data } = await publicAxios.get('Immigration/GetArrivalAndDepartureByCountry')
                await this.generteChartMultipleData(data)
                this.showChart = true

            },
            async getTopArrivalCountryInPilgrimageData() {
                const { data } = await publicAxios.get('Pilgrimage/GetVisitorsListByCountry')
                this.totalKeyValueDatas = this.generteChartKeyValueData(data, "TOP 5 COUNTRY WISE VISITORS IN PILGRIMAGE")

            },
            async getTopArrivalCountryInMuseumData() {
                const { data } = await publicAxios.get('Museum/GetVisitorsListByCountry')
                this.totalKeyValueDatas = this.generteChartKeyValueData(data, "TOP 5 COUNTRY WISE VISITORS IN MUSEUMS")

            },
            async getTopArrivalCountryInNationalParkData() {
                const { data } = await publicAxios.get('NationalPark/GetVisitorsByCountry')
                this.totalKeyValueDatas = this.generteChartKeyValueData(data, "TOP 5 COUNTRY WISE VISITORS IN NATIONAL PARK")
            },
            //async getLineChart() {
            //    const { data } = await axios.post('NRBExchange/GetGrossExchangeEarningsGraphAsync', {})
            //    let formatedData = this.getChartData(data)
            //    this.barsDat = formatedData
            //},
            async getTotalArrivalCountryData() {
                const { data } = await publicAxios.get('Immigration/GetArrivalDepartureByCountryList?Type=Arrival')
                this.totalArrivalCountryData = data
                console.log('this.totalCountryArrival', this.totalArrivalData)
            },
            async getTotalDepartureCountryData() {
                const { data } = await publicAxios.get('Immigration/GetArrivalDepartureByCountryList?Type=Departure')
                this.totalDepartureCountryData = data
                console.log('this.totalCountryDeparture', this.totalDepartureData)
            },
            async getHotelData() {
                this.showChart = false
                this.chartTitle = "Hotel"
                const { data } = await publicAxios.get('Hotel/GetTotalsRoomsOccupied?Type=Hotel')
                this.addtionalData = this.generateChartDataNew(data,"REGISTERED HOTEL, ROOM, OCCUPIED ROOMS")
                this.showChart = true
            },
            async getHomeStayData() {
                this.showChart = false
                this.chartTitle = "Home Stay"
                const { data } = await publicAxios.get('Hotel/GetHomestayTotalsRoomsOccupiedForHomepage?Type=HomeStay')
                this.addtionalData = this.generateChartDataNew(data,"REGISTERED HOMESTAY, PAX, OCCUPIED PAX")
                this.showChart = true
            },
            updateParams(newProps) {
                console.log(newProps)
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                console.log(params)
                this.updateParams({ pageNo: params.currentPage });
                this.loadBranchList();
            },

            onPerPageChange(params) {
                this.updateParams({ pageSize: params.currentPerPage });
                this.loadBranchList();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadBranchList();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadBranchList();
            },
            dialogueClose() {
                this.snackbar.modal = false
                this.snackbar.branchServices = false
                this.snackbar.branchDetails = false
            },
        },
        async mounted() {
            this.languageCallback();
            this.getNow();
            this.getTopArrivalCountryInPilgrimageData();
            this.getTopArrivalCountryInMuseumData();
            this.getTopArrivalCountryInNationalParkData();
            this.$nextTick(() => {
                this.$refs.myBtn.click()
                this.provinceChange(1)
            });
            this.generateChartDataNew();
        },
    }
</script>
<style lang="scss" scoped>
    $blue: #265b93;
    $red: #B72626;
    @media screen and (max-width: 800px) {
      .responsive-mp-none {
        margin: unset !important;
        padding: unset !important;
      }
      .search-section {
        padding: 0px !important;
        margin-top: 2rem;
      }
      ::v-deep .ps-16 {
        padding-left: 0px !important;
      }
      .province-font {
        font-size: 11px;
      }
      .carousel-background {
        height: 7rem !important;
        width: 7rem !important;
        margin-top: 1rem;

        .icon {
          font-size: 3rem !important;
        }
        p{
          font-size: 11px;
        }
      }
      .custom-design {
        .first {
          height: 8rem !important;
          width: 8rem !important;

          .second {
            height: 7rem !important;
            width: 7rem !important;

            .third {
              height: 6rem !important;
              width: 6rem !important;

              span {
                font-size: 11px;
              }
            }
          }
        }
      }
      .responsive-slider{
        flex-wrap: nowrap;
        overflow-x: scroll;
      }
      #chart-container{
        overflow-y: scroll;
      }
      h1,h2,h3,h4{
        font-size: 12px;
      }
      ::v-deep .vgt-table thead th, ::v-deep .vgt-table tbody td {
        font-size: 11px !important;
      }
      ::v-deep .vgt-wrap__footer{
        select,label,.footer__navigation__page-info,span,.footer__row-count__label{
          font-size: 11px !important;
        }
        .chevron{
          border-right-color: white !important;
        }
      }

    }

    .vl {
        height: 2rem;
    }
    .content-paddings {
        padding: 0 6rem;
    }

    .nav-left-top-font {
        color: red;
        font-weight: 600;
        font-size: 0.8rem;
    }

    .menu-font {
        font-weight: 500;
        font-size: 0.8rem;

        &:hover {
            color: red;
        }
    }

    .nav-left-bottom-font {
        font-weight: 600;
        font-size: 0.7rem;
    }

    .logo-img {
        height: 4em;
    }


    .search-section {
        padding: 5rem 15rem 5rem 15rem;
    }

    #app_bar {
        background-image: url('../assets/search-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;

        .app-bar-menu {
            background-color: #0e1f3eb0;
        }
    }


    .province-style {
        &:hover {
            background-color: rgb(157 25 25);
        }
    }
    .active-province {
        background-color: rgb(157 25 25);
    }

    .custom-design {
        .chart-active {
            background-color: #4A148C3a !important;

            .second {
                background-color: #FF8A659b !important;

                .third {
                    background-color: rgb(157, 25, 25) !important;
                }
            }
        }

        .first {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 10rem;
            width: 10rem;
            background-color: #3f51b53a;
            border-radius: 50%;

            &:hover {
                background-color: #4A148C3a;
                cursor: pointer;
                .second {
                    background-color: #FF8A659b;

                    .third {
                        background-color: #B71C1C;
                    }
                }
            }

            .second {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 9rem;
                width: 9rem;
                background-color: #DCE775;
                border-radius: 50%;

                .third {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    height: 8rem;
                    width: 8rem;
                    background-color: #FF6F00;
                    border-radius: 50%;

                    .statistics-font {
                        color: white;
                    }
                }
            }
        }
    }

    .carousel-background {
        height: 9rem;
        width: 9rem;
        background-color: rgba(255, 255, 255, 0.678);
        border-radius: 50%;

        .icon {
            color: #0a466f;
            font-size: 4rem;
        }

        .font {
            color: #10203f;
        }
    }

    #notice {
        background-color: #0a4472;
        color: white;
            }

    #footer {
        background-image: linear-gradient(180deg, #122341 0%, #122341 58%, #000b2c 100%);

        h3,
        a,
        p {
            color: white;
            text-decoration: none;
        }

        p {
            font-size: 14px;
        }
    }

    .statistics {
        h2 {
            color: red;
            padding: 1rem;
        }
    }
    .hidden-section{
        .count{
            display: none !important;
        }
    }
    .show-hidden-section {
        display: block;

        .count {
            display: block !important;
        }

        .vl {
            border-left: 2px solid white;
        }
    }
    .chart-height {
        height: 33rem;
    }
    .link {
        a {
            &:hover {
                color: #26A69A !important;
            }
        }
    }
    .right-border {
        border-right: 1px solid #928b8b9c;
    }
    ::v-deep .vgt-table thead th {
        background: $blue !important;
        color: #ffffff !important;
    }

    ::v-deep .vgt-wrap__footer {
        background: $blue !important;
    }

</style>
