<template>
    <v-card class="scroll-thin">
        <v-toolbar dark color="#236aac" class="darken-1">
            <v-toolbar-title>
                {{$t('navBarLogin.pending_user_list')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <vue-good-table
                        mode="remote"
                        :pagination-options="{ enabled: true, }"
                        :rows="pendingUserList"
                        :columns="pendingUserColumns"
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'actions'">
                            <v-btn class="px-2 mx-2" color="success"
                                   @click="activeMethod(props.row,true)">{{$t('approve')}}</v-btn>
                          <v-btn color="px-2 mx-2 error" @click="activeMethod(props.row,false)"
                          >{{$t('decline')}}</v-btn>
                    </span>
                        <span v-else-if="props.column.field == 'status'">
                           <v-chip dark :color="props.row.status ==='Pending'?'orange darken-4':'red darken-4'"
                           >{{props.row.status}}</v-chip>
                    </span>
                    </template>
                </vue-good-table>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('close')}}</v-btn>
        </v-card-actions>
        <v-dialog
                v-model="snackbar.modal"
                transition="dialog-bottom-transition"
                max-width="690"
                scrollable
                persistent
                hide-overlay
        >
            <v-card class="scroll-thin" v-if="snackbar.modal">
                <v-toolbar dark color="#236aac" class="darken-1">
                    <v-toolbar-title>
                        {{$t('add_remarks')}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="remarksDialogueClose">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-content>
                        <v-textarea
                                label="Remarks"
                                auto-grow
                                outlined
                                rows="7"
                                row-height="25"
                                shaped
                        ></v-textarea>
                    </v-content>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="green darken-4" @click="submitRemarks">{{$t('remarks')}} {{$t('submit')}}</v-btn>
                    <v-btn dark color="red darken-1" @click="remarksDialogueClose">{{$t('cancel')}}</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-card>

</template>

<script>
    import axios from "axios";
    import publicAxios from "../simple-axios";

    export default {
        name: "PendingUserList",
        methods: {
            submitRemarks() {
            },
            openRemarksDialogue() {
                this.snackbar.modal = true
            },
            remarksDialogueClose() {
                this.snackbar.modal = false
            },
            dialogueClose() {
                this.$emit("dialogueClose");
                this.snackbar.modal = false
            },
            async frontPendingList(){
              try {
                  const { data } = await publicAxios.post(this.pendingURL,{})
                  this.pendingUserList = data.data
              }
              catch (e) {
                  console.log(e)
              }
            },
            loadNormalPendingListData() {
                this.frontPendingList()
                this.pendingUserColumns.push(
                    {
                        label: 'Division',
                        field: 'divisionName',
                    },
                    {
                        label: 'Branch',
                        field: 'branchName',
                    },
                    {
                        label: 'Requested User',
                        field: 'userName',
                    },
                    {
                        label: 'Status',
                        field: 'status',
                    },
                    {
                        label: 'Remarks',
                        field: 'remarks',
                    },
                )
            },
            loadAdminPendingListData() {
                this.pendingMethods();
                this.pendingUserColumns.push(
                    {
                        label: this.$t('table.business_sector'),
                        field: 'divisionName',
                    },
                    {
                        label: this.$t('table.brand_name'),
                        field: 'branchName',
                    },
                    {
                        label: this.$t('requested_user'),
                        field: 'userName',
                    },
                    {
                        label: this.$t('remarks'),
                        field: 'remarks',
                    },
                    {
                        label: this.$t('actions'),
                        field: 'actions',
                    },
                )
            },
            activeMethod(data, status) {
                axios
                    .post("Branch/ApproveBranch", {
                        branchID: data.branchID,
                        userID: data.userID,
                        isApproved: status
                    })
                    .then(() => {
                        this.pendingMethods();
                    });
                if (!status) {
                    this.openRemarksDialogue()
                }
            },
            async pendingMethods() {
                // console.log(this.countBranch);
                let {data} = await axios.post("Branch/GetPendingBranchListByDivisionID",{
                   divisionName:this.divisionName
                });
                console.log(data);
                if (data) {
                    this.pendingUserList = data;
                }
                console.log(this.pendingData);
            },
        },
        props: [
            "isSuperAdmin",
            "divisionName",
            "pendingURL",
        ],
        data() {
            return {
                snackbar: {
                    modal: false
                },
                pendingUserList: [],
                pendingUserColumns: []
            }
        },
        created() {
            if (this.isSuperAdmin) {
                this.loadAdminPendingListData()
                /* this.loadNormalPendingListData()*/
            } else {
                this.loadNormalPendingListData()
            }
        }
    }
</script>

<style scoped lang="scss">
    $blue: #236aac;
    $blue1: #061c6d;
    $red: #B72626;
    $grey: #eeeeee51;
    $grey1: #eeeeee99;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4e434361;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4e434361;
    }
</style>