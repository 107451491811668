<template>
    <v-app id="inspire">
        <v-container
                style="background-color: #fff;display:grid; align-item:center;justify-content:center;align-content:center;justify-items:center;height:100vh;text-align:center;">
            <v-card color="indigo darken-1" class="pa-5 text--center" dark v-if="!passwordChanged">
                <h1 class="text-h1 text-center text--center mb-1"> CeTMIS </h1>
                <h3 style="text-align:center;margin-bottom:1rem">Reset Your Password</h3>
                <router-link to="/homepage" class="homePageLink"> Go Back to the Website</router-link>
                <v-card-text>
                    <v-row justify="center" class="pa-3" no-gutters v-if="!resetConfirm">
                        <v-col md="12" class="">
                            <div class="d-flex">
                                <v-text-field
                                        v-model="resetCode"
                                        placeholder="Code"
                                        outlined
                                        dense
                                        class="pr-5"
                                ></v-text-field>
                                <v-btn outlined
                                       dark
                                       :loading="resetLoading"
                                       :disabled="resetLoading"
                                       @click="ConfirmResetCode"
                                >Check
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col md="12" v-if="resetLoading">
                            <v-progress-linear
                                    buffer-value="0"
                                    color="indigo"
                                    reverse
                                    stream
                                    value="0"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-row justify="center" v-if="resetConfirm" no-gutters>

                        <v-col md="12" class="pa-5">
                            <v-text-field
                                    placeholder="New Password"
                                    outlined
                                    dense
                                    type="password"
                                    v-model="password"
                                    :error-messages="passwordErrors"
                                    @input="$v.password.$touch()"
                                    @blur="$v.password.$touch()"
                            ></v-text-field>
                            <v-text-field
                                    placeholder="Confirm Password"
                                    outlined
                                    dense
                                    type="password"
                                    v-model="confirmPassword"
                                    :error-messages="confirmPasswordErrors"
                                    @input="$v.confirmPassword.$touch()"
                                    @blur="$v.confirmPassword.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined
                                   dark
                                   :disabled="submitLoading"
                                   :loading="submitLoading"
                                   @click="ConfirmResetPassword"
                            >Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-snackbar
                        :color="snackbar.color"
                        top
                        v-model="snackbar.snackbar"
                >
                    {{ snackbar.text }}
                    <v-btn
                            @click="snackbar.snackbar = false"
                            dark
                            text
                    >
                        Close
                    </v-btn>
                </v-snackbar>
            </v-card>
            <v-card color="indigo darken-1" class="pa-5 text--center" dark v-else>
                <h3 style="text-align:center;margin-bottom:1rem">Your Password has been reset</h3>
                <router-link to="/homepage" class="homePageLink"> Go Back to the Website</router-link>
            </v-card>
        </v-container>
    </v-app>
</template>

<script>
    import axios from 'axios'
    import {required, sameAs} from "vuelidate/lib/validators";

    export default {
        name: "PasswordReset",
        computed: {
            UserID() {
                return parseInt(this.$route.params.userID);
            },
            passwordErrors() {
                const errors = [];
                if (!this.$v.password.$dirty) return errors;
                !this.$v.password.required && errors.push("Password is required");
                return errors;
            },
            confirmPasswordErrors() {
                const errors = [];
                if (!this.$v.confirmPassword.$dirty) return errors;
                !this.$v.confirmPassword.required &&
                errors.push("Confirm Password is required.");
                !this.$v.confirmPassword.sameAsPassword &&
                errors.push("Password must be identical.");
                return errors;
            },
        },
        data() {
            return {
                submitLoading: false,
                resetLoading: false,
                resetCode: null,
                resetConfirm: false,
                passwordChanged: false,
                password: '',
                confirmPassword: '',
                snackbar: {
                    snackbar: false,
                    text: '',
                    color: ''
                }
            }
        },
        methods: {
            async ConfirmResetCode() {
                this.resetLoading = true
                const {data} = await axios.post('User/ConfirmResetCode', {
                    userID: this.UserID,
                    resetCode: this.resetCode
                })
                this.resetLoading = false
                if (!data.success) {
                    this.snackbar.snackbar = true
                    this.snackbar.color = 'red'
                    this.snackbar.text = data.errors[0]
                } else {
                    this.resetConfirm = true
                }
            },
            async ConfirmResetPassword() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.submitLoading = true
                    const {data} = await axios.post('User/ConfirmResetPassword ', {
                        userID: this.UserID,
                        resetCode: this.resetCode,
                        password: this.password
                    })
                    this.submitLoading = false
                    if (!data.success) {
                        this.snackbar.snackbar = true
                        this.snackbar.color = 'red'
                        this.snackbar.text = data.errors[0]
                    } else {
                        this.passwordChanged = true
                    }
                    console.log('password', data)
                }
            }
        },
        validations: {
            password: {required},
            confirmPassword: {
                required,
                sameAsPassword: sameAs("password")
            }
        }
    }
</script>

<style scoped lang="scss">
.homePageLink{
    text-decoration: none;
    color: white;
    font-style: italic;
    &:hover{
        text-decoration: underline;
    }
}
</style>