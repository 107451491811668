<template>
    <div class="pt-16">
        <v-tabs id="search_tab">
            <v-tab href="#tab-search">{{$t('search')}}</v-tab>
            <v-tab href="#tab-advanced-search">{{$t('detailed_search')}}</v-tab>
            <v-tab-item
                    value="tab-search"
            >
                <v-card
                        flat
                        tile
                >
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="9"
                                >
                                    <v-autocomplete :items="searchOptions"
                                                    :loading="isLoading"
                                                    :search-input.sync="search"
                                                    @change="changeValue(search)"
                                                    @keydown.enter.prevent="formSubmit(search)"
                                                    @keyup="setBranchName(search)"
                                                    @select="selectedValue(search)"
                                                    clearable
                                                    hide-no-data
                                                    hide-selected
                                                    solo
                                                    dense
                                                    item-text="branchName"
                                                    item-value="branchName"
                                                    v-model="searchSuggestions.BranchName">
                                        <template v-slot:label>
                                            {{ $t("start_typing") }}
                                        </template>
                                    </v-autocomplete>
                                    <!-- <v-text-field
                                             solo
                                             label="Type keywords and search for answers"
                                             prepend-inner-icon="search"
                                             clearable
                                             v-model="searchData.BranchName"
                                             @keyup.enter="formSubmit"
                                     ></v-text-field>-->
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <div class="grid-container">
                                        <v-btn @click="formSubmit" class="search me-2" color="#077dfa" dark>{{$t('search')}}</v-btn>
                                        <v-btn @click="clearData" class="clear" color="rgb(157, 25, 25)" dark>{{$t('clear')}}</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                    value="tab-advanced-search"
            >
                <v-card
                        flat
                        tile
                >
                    <v-card-text>
                        <advancedSearch @advancedSearchResult="advancedSearchResult"
                                        @clearData="clearData"
                                        @loading="loading=true"
                        ></advancedSearch>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
        <div class="text-center" style="width:100%;" v-if="loading">
            <img alt="Loading" src="../assets/loader.gif">
        </div>
        <div v-if="searchResult.length">
            <vue-good-table :columns="columns"
                            :pagination-options="{ enabled: true, mode:'record',perPageDropdown: [20,50, 100,200] }"
                            :rows="searchResult"
                            max-height="1062px"
                            :totalRows="totalRecords"
                            @on-column-filter="onColumnFilter"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote"
                            styleClass="vgt-table custom-table">
                <div slot="table-actions">
                    <v-icon @click="clearData">mdi-close</v-icon>
                </div>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-chip @click="loadServices(props.row)"
                                class="ml-2"
                                color="green"
                                small
                                text-color="white">
                            services
                        </v-chip>
                        <v-chip @click="loadDetails(props.row)"
                                class="ml-2 darken-2"
                                color="rgb(157, 25, 25)"
                                small
                                text-color="white">
                            Details
                        </v-chip>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog
                    hide-overlay
                    max-width="690"
                    persistent
                    scrollable
                    transition="dialog-bottom-transition"
                    v-model="snackbar.modal"
            >

                <BranchServices :branchID="branchData.branchID"
                                :divisionName="branchData.divisionName"
                                @dialogueClose="dialogueClose"
                                v-if="snackbar.branchServices"
                ></BranchServices>
                <BranchDetails :branchID="branchData.branchID"
                               :divisionName="branchData.divisionName"
                               @dialogueClose="dialogueClose"
                               v-if="snackbar.branchDetails"
                ></BranchDetails>

            </v-dialog>
        </div>
        <v-snackbar
                :timeout="snackbar.timeout"
                top
                v-model="snackbar.snackbar"
        >
            {{ snackbar.message }}
            <v-btn
                    @click="snackbar.snackbar = false"
                    color=" error "
                    text
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import advancedSearch from './AdvancedSearchSection'
    import BranchServices from './servicesProvinces/BranchServices'
    import BranchDetails from './servicesProvinces/BranchDetails'
    import publicAxios from "../simple-axios";

    export default {
        name: "SearchSection",
        components: {
            advancedSearch,
            BranchServices,
            BranchDetails,
        },
        computed: {},
        data: () => ({
            snackbar: {
                modal: false,
                branchDetails: false,
                branchServices: false,
                snackbar: false,
                timeout: 5000
            },
            searchSuggestions: {
                BranchName: ''
            },
            totalRecords:0,
            search: null,
            isLoading: false,
            loading: false,
            searchResult: [],
            searchOptions: [],
            searchData: {
                divisionID: 0,
                provinceID: 0,
                BranchName: "",
                sort: [
                    {
                        field: "BranchName",
                        type: "desc"
                    }
                ],
                page: 1,
                perPage: 20
            },
            branchData: {
                divisionName: '',
                branchID: 0,
            },
            columns: [
                {
                    label: "Province Name",
                    field: "provinceName"
                },
                {
                    label: "Sector Name",
                    field: "divisionName"
                },
                {
                    label: "Name",
                    field: "branchName"
                },
                {
                    label: "Address",
                    field: "branchAddress"
                },
                {
                    label: "Phone",
                    field: "branchPhoneNumber"
                },
                {
                    label: "Website",
                    field: "branchWebsite"
                },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }
            ],

        }),
        methods: {
            clearData() {
                this.searchResult = []
            },
            formSubmit() {
                this.loadBranches()
            },
            changeValue() {
                this.setBranchName(this.searchSuggestions.BranchName)
                this.formSubmit()
            },
            setBranchName(value) {
                console.log('value',value)
                this.searchData.BranchName = value
            },
            async loadOptions(value) {
                const { data } = await publicAxios.post('PublicDetail/BranchListAsync', {
                    divisionID: 0,
                    provinceID: 0,
                    BranchName: value,
                })
                this.searchOptions = data.data
            },
             loadBranches() {
                this.loading = true
                let param = {
                    PageSize: this.searchData.perPage,
                    PageNo: this.searchData.page,
                    OrderType: this.searchData.sort[0].type,
                    OrderBy: this.searchData.sort[0].field,
                    DivisionID: this.searchData.divisionID,
                    ProvinceID: this.searchData.provinceID,
                    BranchName: this.searchData.BranchName
                };
                 publicAxios.post('PublicDetail/BranchListAsync', param).then(response => {
                     console.log(response);
                    this.totalRecords = response.data.totalCount;
                    this.searchResult = response.data.data
                    this.loading = false
                    if (!this.searchResult.length) {
                        this.snackbar.snackbar = true
                        this.snackbar.message = "No Data Found"
                        this.search = null
                        this.searchSuggestions.BranchName = ''
                    }
                }).catch(e=>{
                    console.log(e)
                })
            },
            advancedSearchResult({ divisionID,provinceID,BranchName}) {
                this.searchData.divisionID =divisionID
                this.searchData.provinceID =provinceID
                this.searchData.BranchName =BranchName
                console.log('this.searchData',this.searchData)
                this.loadBranches()
            },
            loadServices({divisionName, branchID}) {
                this.snackbar.modal = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                this.snackbar.branchServices = true
            },
            loadDetails({divisionName, branchID}) {
                this.snackbar.modal = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                this.snackbar.branchDetails = true
            },
            updateParams(newProps) {
                console.log(newProps)
                this.searchData = Object.assign({}, this.searchData, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadBranches();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadBranches();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadBranches();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadBranches();
            },

            dialogueClose() {
                this.snackbar.modal = false
                this.snackbar.branchDetails = false
                this.snackbar.branchServices = false
            },
        },
        watch: {
            search(val) {
                // Items have already been loaded
                if (this.searchOptions.length > 0) return
                // Items have already been requested
                if (this.isLoading) {
                    return
                }

                this.isLoading = true

                // Lazily load input items
                this.loadOptions(val)

                this.isLoading = false
            },
        },
    };
</script>
<style lang="scss" scoped>
    $blue: #265b93;
    $red: #B72626;
    ::v-deep .vgt-table thead th {
        background: $blue !important;
        color: #ffffff !important;
    }

    ::v-deep .vgt-wrap__footer {
        background: $blue !important;
    }

    .search {
        background-color: $blue;
    }

    .clear {
        background-color: $red;
    }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .bread {
        margin-top: 4em;
    }

    .v-breadcrumbs {
        background-color: #f9f7f72e !important;
    }

    .v-avatar {
        margin-left: -0.4em;

        .v-icon {
            color: #1e88e5;
            font-size: 2.5em;
        }
    }
    ::v-deep .theme--light.v-tabs-items, ::v-deep .theme--light.v-tabs > .v-tabs-bar {
        background-color: transparent !important;

        .theme--light.v-card {
            background-color: transparent !important;
        }

        .theme--light.v-sheet {
            background-color: transparent !important;
        }
    }
    .v-tab {
        height: 2.5rem;
        color: white !important;
        font-weight: 600;
        margin-right: 0.5rem;
        background-color: rgb(255 0 0 / 19%);
        border-radius: 6px;
    }
    .v-tab--active {
        background-color: rgb(157, 25, 25);
    }
    ::v-deep .v-input__slot {
        background-color: white !important;
    }

</style>
