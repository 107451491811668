<template>
    <v-form>
        <v-row no-gutters>
            <v-col
                    class="ma-0 pa-0"
                    cols="12"
                    md="4"
            >
                <v-autocomplete v-model="searchSuggestions.BranchName"
                                :items="searchOptions"
                                :loading="isLoading"
                                :search-input.sync="search"
                                hide-no-data
                                :totalRows="totalRecords"
                                hide-selected
                                solo
                                dense
                                item-text="branchName"
                                item-value="branchName"
                                clearable
                                @keyup="setBranchName(search)"
                                @keydown.enter.prevent="formSubmit"
                                @change="changeValue(search)"
                                class="ma-0 pa-0">
                    <template v-slot:label>
                        {{ $t("start_typing") }}
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col
                    class="ma-0 pa-0"
                    cols="12"
                    md="3"
            >
                <v-autocomplete
                        v-model="searchData.provinceID"
                        :items="provinces"
                                   solo
                                                    dense
                                clearable
                        class="ma-0 pa-0 ml-1 mr-1"
                        item-text="value"
                        item-value="id"
                        @change="provinceChange"
                ><template v-slot:label>
    {{ $t("province") }}
</template></v-autocomplete>
            </v-col>
            <v-col
                    class="ma-0 pa-0"
                    cols="12"
                    md="3"
            >
                <v-autocomplete v-model="searchData.divisionID"
                                :items="divisions"
                                solo
                                dense
                                class="ma-0 pa-0 ml-1 mr-1"
                                item-text="divisionName"
                                item-value="divisionID"
                                @change="loadBranches">
                    <template v-slot:label>
                        {{ $t("business_sector") }}
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col
                    class="ma-0 pa-0"
                    cols="12"
                    md="2"
            >
                <v-spacer></v-spacer>
                <div class="">
                    <v-btn dark class="search mr-2" color="#265b93" @click="formSubmit">{{$t('search')}}</v-btn>
                    <v-btn dark  color="#B72626" class="clear" @click="clearData">{{$t('clear')}}</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
    import publicAxios from "../simple-axios";
    export default {
        name: "SearchSection",
        components: {
        },
        data() {
            return {
                totalRecords:0,
                searchSuggestions: {
                    BranchName: ''
                },
                search: null,
                isLoading: false,
                searchResult: [],
                searchOptions: [],
                snackbar: {
                    modal: false
                },
                searchData: {
                    divisionID: 0,
                    provinceID: 0,
                    BranchName: "",
                },
                row: [],
                loading:false,
                provinces: [
                    //{ id: 0, value:'All'},
                    { id: 1, value:'Province No. 1'},
                    { id: 2, value:'Madhesh Province'},
                    { id: 3, value:'Bagmati Province'},
                    { id: 4, value:'Gandaki Province'},
                    { id: 5, value:'Lumbini Province'},
                    { id: 6, value:'Karnali Province'},
                    { id: 7, value:'Sudurpashchim Province'},
                ],
                divisions: [],
                serverParams: {
                    columnFilters: {},
                    sort: [
                        {
                            field: "branchID",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
            }
        },
        computed: {

        },
        mounted() {
            this.loadProvinces()
        },
        methods: {
            clearData() {
                this.searchSuggestions.BranchName= ''
                this.searchResult = []
                this.searchData = []

                this.$emit('clearData')
            },
            formSubmit(){
                this.loadBranches()
            },
            changeValue() {
                this.setBranchName(this.searchSuggestions.BranchName)
                this.formSubmit()
            },
            setBranchName(value){
                this.searchData.BranchName = value
            },
            async loadOptions() {
             //     {
             //            divisionID: 0,
             //            provinceID: 0,
             //            BranchName: this.search,
             //     }
                console.log("lkjlk")

                const { data } = await publicAxios.post('PublicDetail/BranchListAsync',this.serverParams)
                this.searchOptions = data.data
                this.totalRecords = data.totalCount
            },
            async loadBranches() {
                this.$emit('loading')
               /* const branches = await publicAxios.post('PublicDetail/BranchListAsync', this.serverParams)
                this.totalRecords = branches.data.totalCount;
                this.row = branches.data.data*/
                if(!this.row.length){
                    this.$emit('noResult')
                }
                this.serverParams.divisionID = this.searchData.divisionID
                this.serverParams.provinceID = this.searchData.provinceID
                this.serverParams.BranchName = this.searchData.BranchName
                this.$emit('advancedSearchResult', this.serverParams)
            },
            //async loadProvinces() {
            //    const provinces = await publicAxios.post('PublicDetail/GetProvinceList' ,{})
            //    this.provinces = provinces.data
            //},
            async loadDivisions(provinceID) {
                const divisions = await publicAxios.post('PublicDetail/GetDivisionListAsync' ,{
                    provinceID
                })
                this.divisions = divisions.data
            },
            provinceChange(value){
                this.loadBranches()
                this.loadDivisions(value)
            },
            dialogueClose() {
                this.snackbar.modal = false
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
        },
        watch: {
            search(val) {
                // Items have already been loaded
                if (this.searchOptions.length > 0) return
                // Items have already been requested
                if (this.isLoading) {
                    return
                }

                this.isLoading = true

                // Lazily load input items
                this.loadOptions(val)

                this.isLoading = false
            },
        },
    };
</script>
<style lang="scss" scoped>
    .grid-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .v-input__slot {
        margin: 0;
    }
    .v-text-field.v-text-field--solo .v-input__control {
        min-height: 30px;
        padding: 0;
    }
    .bread {
        margin-top: 4em;
    }

    .v-breadcrumbs {
        background-color: #f9f7f72e !important;
    }

    .v-avatar {
        margin-left: -0.4em;

        .v-icon {
            color: #1e88e5;
            font-size: 2.5em;
        }
    }
    .search{
        margin-right: 3px;
    }
</style>