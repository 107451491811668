<template>
    <v-card>
        <v-toolbar dark color="#236aac">
            <v-toolbar-title>
                {{ divisionName }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-tabs v-if="!this.isAcademic">
                <v-tab href="#tab-service" v-if="hasServices">
                    <span v-if="this.isTouristPolice">{{$t('table.incidents')}}</span>
                    <!--<span v-else>Services by {{ divisionName }}</span>-->
                    <span v-else>{{$t('table.services')}}</span>
                </v-tab>
                <v-tab href="#tab-packages" v-if="hasPackages" >
                <!--    Packages by {{ divisionName }}-->
                {{$t('table.packages')}}
                </v-tab>
                <v-tab href="#tab-destinations" v-if="hasDestinations">{{$t('table.destinations')}} </v-tab>
                <v-tab href="#tab-hotelRooms" v-if="isHotel">{{$t('table.available_rooms')}} </v-tab>
                <v-tab href="#tab-buyingExchange" v-if="hasExchangeRate">{{$t('table.buying_rates')}} </v-tab>
                <v-tab href="#tab-sellingExchange" v-if="hasExchangeRate">{{$t('table.selling_rates')}} </v-tab>
                <v-tab href="#tab-peaks" v-if="hasPeaks">{{$t('table.peaks')}} </v-tab>
                <v-tab-item
                        value="tab-service"
                        v-if="hasServices"
                >
                    <vue-good-table
                            mode="remote"
                            :totalRows="servicesData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                            :rows="servicesData.services"
                            :columns="serviceColumns"
                            @on-page-change="onServicesPageChange"
                            @on-sort-change="onServicesSortChange"
                            @on-column-filter="onServicesColumnFilter"
                            @on-per-page-change="onServicesPerPageChange"
                    >
                    </vue-good-table>
                </v-tab-item>
                <v-tab-item
                        value="tab-packages"
                        v-if="hasPackages"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="packagesData.packages"
                            :columns="packageColumns"
                            @on-page-change="onPackagesPageChange"
                            @on-sort-change="onPackagesSortChange"
                            @on-column-filter="onPackagesColumnFilter"
                            @on-per-page-change="onPackagesPerPageChange"
                            :totalRows="packagesData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >

                    </vue-good-table>
                </v-tab-item>
                <v-tab-item
                        value="tab-destinations"
                        v-if="hasDestinations"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="destinationData.destinations"
                            :columns="destinationColumns"
                            @on-page-change="onDestinationPageChange"
                            @on-sort-change="onDestinationSortChange"
                            @on-column-filter="onDestinationColumnFilter"
                            @on-per-page-change="onDestinationPerPageChange"
                            :totalRows="destinationData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >
                    </vue-good-table>
                </v-tab-item>
                <v-tab-item
                        value="tab-hotelRooms"
                        v-if="isHotel"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="hotelRoomsData.availableHotelRooms"
                            :columns="availableRoomColumns"
                            @on-page-change="onHotelDataPageChange"
                            @on-sort-change="onHotelDataSortChange"
                            @on-column-filter="onHotelDataColumnFilter"
                            @on-per-page-change="onHotelDataPerPageChange"
                            :totalRows="hotelRoomsData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >
                    </vue-good-table>
                </v-tab-item>
                <v-tab-item
                        value="tab-sellingExchange"
                        v-if="hasExchangeRate"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="sellingData.sellingDataRows"
                            :columns="sellingDataColumns"
                            @on-page-change="onSellingDataPageChange"
                            @on-sort-change="onSellingDataSortChange"
                            @on-column-filter="onSellingDataColumnFilter"
                            @on-per-page-change="onSellingDataPerPageChange"
                            :totalRows="sellingData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'fromCurrency'">
                                <span>{{props.row.fromCurrency +'-'+ props.row.toCurrency }}</span>
                            </span>
                        </template>
                    </vue-good-table>
                </v-tab-item>
                <v-tab-item
                        value="tab-buyingExchange"
                        v-if="hasExchangeRate"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="buyingData.buyingDataRows"
                            :columns="buyingDataColumns"
                            @on-page-change="onBuyingDataPageChange"
                            @on-sort-change="onBuyingDataSortChange"
                            @on-column-filter="onBuyingDataColumnFilter"
                            @on-per-page-change="onBuyingDataPerPageChange"
                            :totalRows="buyingData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'fromCurrency'">
                                <span>{{props.row.fromCurrency +'-'+ props.row.toCurrency }}</span>
                            </span>
                        </template>
                    </vue-good-table>
                </v-tab-item>
                <v-tab-item
                        value="tab-peaks"
                        v-if="hasPeaks"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="peakData.peakDataRows"
                            :columns="peakDataColumns"
                            @on-page-change="onPeakDataPageChange"
                            @on-sort-change="onPeakDataSortChange"
                            @on-column-filter="onPeakDataColumnFilter"
                            @on-per-page-change="onPeakDataPerPageChange"
                            :totalRows="peakData.totalRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >
                      <!--  <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'fromCurrency'">
                                <span>{{props.row.fromCurrency +'-'+ props.row.toCurrency }}</span>
                            </span>
                        </template>-->
                    </vue-good-table>
                </v-tab-item>
            </v-tabs>
            <v-tabs v-if="this.isAcademic">
                <v-tab v-for="(tab,index) in Object.keys(tabRows)" :key="index"
                       :href="'#tab-service-'+index"
                >{{tab.toUpperCase()}}</v-tab>

                <v-tab-item
                        v-for="(tab,index) in Object.keys(tabRows)" :key="index"
                        :value="'tab-service-'+index"
                >
                    <vue-good-table
                            mode="remote"
                            :rows="tab==='academic'?tabsData.academic:tab==='training'?tabsData.training:tabsData.internship"
                            :columns="tab==='academic'?academicDataColumns:tab==='training'?trainingDataColumns:internshipDataColumns"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows.totalTabsDataRows"
                            :pagination-options="{ enabled: true,perPageDropdown: [20,50, 100,200]  }"
                    >
                    </vue-good-table>
                </v-tab-item>
            </v-tabs>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('form.cancel')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import publicAxios from '../../simple-axios'
    export default {
        name: "BranchServices",
        props: [
            'branchID',
            'divisionName'
        ],
        data() {
            return {
                totalRows:0,
                servicesData:{
                    services: [],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "serviceName",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                packagesData:{
                    packages: [],
                    packageColumns: [
                        {
                            label: 'Package Name',
                            field: 'packageName',
                        },
                        {
                            label: 'Description',
                            field: 'packageDescription',
                        },
                    ],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "packageName",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                destinationData:{
                    destinations: [],
                    destinationColumns: [
                        {
                            label: 'Destination',
                            field: 'destination',
                        },
                    ],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "destination",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                hotelRoomsData:{
                    availableHotelRooms: [],
                    availableRoomColumns: [
                        {
                            label: 'Room Number',
                            field: 'roomNumber',
                        },
                        {
                            label: 'Category',
                            field: 'category',
                        },
                        {
                            label: 'Type',
                            field: 'type',
                        },
                        {
                            label: 'Fare',
                            field: 'fare',
                        },
                    ],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "roomNumber",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                sellingData:{
                    sellingDataRows: [],
                    sellingDataColumns: [
                        {
                            label: 'Currency(From - To)',
                            field: 'fromCurrency',
                        },
                        {
                            label: 'Selling Rate',
                            field: 'sellingRate',
                        },
                    ],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "sellingRateID",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                buyingData:{
                    buyingDataRows: [],
                    buyingDataColumns: [
                        {
                            label: 'Currency(From - To)',
                            field: 'fromCurrency',
                        },
                        {
                            label: 'Min Amount',
                            field: 'minAmount',
                        },
                        {
                            label: 'Max Amount',
                            field: 'maxAmount',
                        },
                        {
                            label: 'BuyingRate',
                            field: 'buyingRate',
                        },
                    ],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "buyingRateID",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                peakData:{
                    peakDataRows: [],
                    peakDataColumns: [

                        {
                            label: 'Mountain',
                            field: 'mountainName',
                        },
                        {
                            label: 'Peak',
                            field: 'peak',
                        },
                    ],
                    totalRows:0,
                    serverParams: {
                        search: "",
                        columnFilters: {},
                        sort: [
                            {
                                field: "mountainID",
                                type: "desc"
                            }
                        ],
                        page: 1,
                        perPage: 20
                    },
                },
                tabsData: [],
                tabRows:{},
                tabColumn:[],
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "branchID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },
            }
        },
        methods: {
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            updateServicesParams(newProps) {
                this.servicesData.serverParams = Object.assign({}, this.servicesData.serverParams, newProps);
            },
            updatePackagesParams(newProps) {
                this.packagesData.serverParams = Object.assign({}, this.packagesData.serverParams, newProps);
            },
            updateDestinationParams(newProps) {
                this.destinationData.serverParams = Object.assign({}, this.destinationData.serverParams, newProps);
            },
            updateHotelDataParams(newProps) {
                this.hotelData.serverParams = Object.assign({}, this.hotelData.serverParams, newProps);
            },
            updateSellingDataParams(newProps) {
                this.sellingData.serverParams = Object.assign({}, this.sellingData.serverParams, newProps);
            },
            updateBuyingDataParams(newProps) {
                this.buyingData.serverParams = Object.assign({}, this.buyingData.serverParams, newProps);
            },
            updatePeakDataParams(newProps) {
                this.peakData.serverParams = Object.assign({}, this.peakData.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onServicesPageChange(params) {
                this.updateServicesParams({page: params.currentPage});
                this.loadServices();
            },
            onPackagesPageChange(params) {
                this.updatePackagesParams({page: params.currentPage});
                this.loadPackages();
            },
            onDestinationPageChange(params) {
                this.updateDestinationParams({page: params.currentPage});
                this.loadDestinations();
            },
            onHotelDataPageChange(params) {
                this.updateHotelDataParams({page: params.currentPage});
                this.loadAvailableRooms();
            },
            onSellingDataPageChange(params) {
                this.updateSellingDataParams({page: params.currentPage});
                this.loadSellingData();
            },
            onBuyingDataPageChange(params) {
                this.updateBuyingDataParams({page: params.currentPage});
                this.loadBuyingData();
            },
            onPeakDataPageChange(params) {
                this.updatePeakDataParams({page: params.currentPage});
                this.loadPeakData();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },
            onServicesPerPageChange(params) {
                this.updateServicesParams({perPage: params.currentPerPage});
                this.loadServices();
            },
            onPackagesPerPageChange(params) {
                this.updatePackagesParams({perPage: params.currentPerPage});
                this.loadPackages();
            },
            onDestinationPerPageChange(params) {
                this.updateDestinationParams({perPage: params.currentPerPage});
                this.loadDestinations();
            },
            onHotelDataPerPageChange(params) {
                this.updateHotelDataParams({perPage: params.currentPerPage});
                this.loadAvailableRooms();
            },
            onSellingDataPerPageChange(params) {
                this.updateSellingDataParams({perPage: params.currentPerPage});
                this.loadSellingData();
            },
            onBuyingDataPerPageChange(params) {
                this.updateBuyingDataParams({perPage: params.currentPerPage});
                this.loadBuyingData();
            },
            onPeakDataPerPageChange(params) {
                this.updatePeakDataParams({perPage: params.currentPerPage});
                this.loadPeakData();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onServicesSortChange(params) {
                this.updateServicesParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadServices();
            },
            onPackagesSortChange(params) {
                this.updatePackagesParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadPackages();
            },
            onDestinationSortChange(params) {
                this.updateDestinationParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadDestinations();
            },
            onHotelDataSortChange(params) {
                this.updateHotelDataParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadAvailableRooms();
            },
            onSellingDataSortChange(params) {
                this.updateSellingDataParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadSellingData();
            },
            onBuyingDataSortChange(params) {
                this.updateBuyingDataParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadBuyingData();
            },
            onPeakDataSortChange(params) {
                this.updatePeakDataParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadPeakData();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            onServicesColumnFilter(params) {
                this.updateServicesParams(params);
                this.loadServices();
            },
            onPackagesColumnFilter(params) {
                this.updatePackagesParams(params);
                this.loadPackages();
            },
            onDestinationColumnFilter(params) {
                this.updateDestinationParams(params);
                this.loadDestinations();
            },
            onHotelDataColumnFilter(params) {
                this.updateHotelDataParams(params);
                this.loadAvailableRooms();
            },
            onSellingDataColumnFilter(params) {
                this.updateSellingDataParams(params);
                this.loadSellingData();
            },
            onBuyingDataColumnFilter(params) {
                this.updateBuyingDataParams(params);
                this.loadBuyingData();
            },
            onPeakDataColumnFilter(params) {
                this.updatePeakDataParams(params);
                this.loadPeakData();
            },
            dialogueClose() {
                this.$emit("dialogueClose");
            },
            loadServiceAndPackages(){
                console.log('divisionName',this.divisionName)
                this.loadServices()
                this.loadPackages()
                if(this.hasDestinations){
                    this.loadDestinations()
                }
                if(this.isHotel){
                    this.loadAvailableRooms()
                }
            },
            async loadServices(){
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "DivisionName":this.divisionName,
                    "BranchID":this.branchID
                }
                let url = "PublicDetail/GetServicesListAsync"
                if(this.isTouristPolice){
                    url = "PublicDetail/GetPoliceIncidentTypeListAsync"
                    this.servicesData.serviceColumns = []
                    console.log()
                    this.servicesData.serviceColumns.push(
                        {label:'Incident',field:'incidentName'},
                        {label:'Description',field:'incidentDescription'},
                    )
                    console.log('this.servicesData.serviceColumns',this.servicesData.serviceColumns)

                }
                const {data} = await publicAxios.post(url,formBody)
                this.servicesData.services = data
                this.servicesData.totalRows = data.hasOwnProperty('totalCount')?data.totalCount:0

            },
            async loadPackages(){
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "DivisionName":this.divisionName,
                    "BranchID":this.branchID
                }
                const packages = await publicAxios.post('PublicDetail/GetPackageListAsync',formBody)
                this.packagesData.packages = packages.data
                this.packagesData.totalRows = packages.totalCount
                console.log('this.packages',this.packages)
            },
            async loadDestinations(){
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "DivisionName":this.divisionName,
                    "BranchID":this.branchID
                }
                const destinations = await publicAxios.post('PublicDetail/GetDestinationListAsync',formBody)
                this.destinationData.destinations = destinations.data
                this.destinationData.totalRows = this.destinations.totalCount
                console.log('this.destinations',this.destinations)
            },
            async loadAvailableRooms(){
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "DivisionName":this.divisionName,
                    "BranchID":this.branchID
                }
                const availableHotelRooms = await publicAxios.post('PublicDetail/GetAvailableRoomListAsync',formBody)
                this.hotelRoomsData.availableHotelRooms = availableHotelRooms.data
                this.hotelRoomsData.totalRows = this.availableHotelRooms.totalCount
                console.log('this.destinations',this.destinations)
            },
            async loadSellingData(){
                let url = ''
                if(this.divisionName === "MoneyExchange"){
                   url =  'MoneyExchange/GetSellingExchangeRateListAsync'
                }
                else {
                    url = 'NRBExchange/GetSellingExchangeRateListAsync'
                }
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "BranchID":this.branchID
                }
                const { data } = await publicAxios.post(url,formBody)
                this.sellingData.sellingDataRows = data.data
                this.sellingData.totalRows = data.totalCount
                console.log('this.sellingData',this.sellingData)
            },
            async loadBuyingData(){
                let url = ''
                if(this.divisionName === "MoneyExchange"){
                   url =  'MoneyExchange/GetBuyingExchangeRateListAsync'
                }
                else {
                    url = 'NRBExchange/GetBuyingExchangeRateListAsync'
                }
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "BranchID":this.branchID
                }
                const { data } = await publicAxios.post(url,formBody)
                this.buyingData.buyingDataRows = data.data
                this.buyingData.totalRows = data.totalCount
                console.log('this.buyingData',this.buyingData)
            },
            async loadPeakData(){
                let url = 'Mountaining/GetMountainList'
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "BranchID":this.branchID
                }
                const { data } = await publicAxios.post(url,formBody)
                this.peakData.peakDataRows = data.data
                this.peakData.totalRows = data.totalCount
                console.log('this.peakData',this.peakData)
            },
            async loadAcademicData(){
                const formBody = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    "DivisionName":this.divisionName,
                    "BranchID":this.branchID
                }
                const { data } = await publicAxios.post('PublicDetail/AcademicDetail',formBody)
                this.tabsData = data
                this.totalTabsDataRows = this.tabsData.totalCount
                this.tabRows = {
                    academic:[
                        {
                            label:'University Name',
                            field:'universityName',
                        },
                        {
                            label:'Academic Progress',
                            field:'academicProgramme',
                        },
                    ],
                    training:[
                        {
                            label:'Training',
                            field:'training',
                        }
                    ],
                    internship:[
                        {
                            label:'Internship Type',
                            field:'internshipType',
                        },
                        {
                            label:'Name of Institute',
                            field:'nameOfInstitute',
                        },
                    ],
                }

                console.log('this.tabsData',this.tabsData)
            }
        },
        mounted(){
            if(this.isAcademic){
                this.loadAcademicData()
            }
            else if(this.hasExchangeRate){
                this.loadSellingData()
                this.loadBuyingData()
            }
            else if(this.hasPeaks){
                this.loadPeakData()
            }
            else{
                this.loadServiceAndPackages()
            }

        },
        computed: {
            hasDestinations() {
                return this.divisionName === "Aviation" ||
                    this.divisionName ==="HeliCopter" ||
                    this.divisionName ==="TourPackageBusiness" ||
                    this.divisionName ==="Transportation" ||
                    this.divisionName ==="TravelTrekkingGuide" ||
                    this.divisionName ==="Trekking"
            },
            hasTotalCounts() {
                return this.divisionName === "TouristPolice"
            },
            hasPeaks() {
                return  this.divisionName === "Mountaineering"
            },
            hasPackages() {
                return  this.divisionName !== "Pilgrimage"
                && this.divisionName !== "NationalPark"
                && this.divisionName !== "Museum"
                && this.divisionName !== "TouristPolice"
                && this.divisionName !== "Income-Generation"
                && this.divisionName !== "MoneyExchange"
                && this.divisionName !== "Mountaineering"
            },
            hasServices() {
                return  this.divisionName !== "Academic"
                && this.divisionName !== "Income-Generation"
                && this.divisionName !== "MoneyExchange"
                && this.divisionName !== "Mountaineering"
            },
            isHotel() {
                return this.divisionName === "Hotel"
            },
            isAcademic() {
                return this.divisionName === "Academic"
            },
            isTouristPolice() {
                return this.divisionName === "TouristPolice"
            },
            isMountaineering() {
                return this.divisionName === "Mountaineering"
            },
            hasExchangeRate() {
                return this.divisionName === "Income-Generation" || this.divisionName === "MoneyExchange"
            },
            serviceColumns(){
                let returnData = [
                    {
                        label: this.$t('table.services'),
                        field: 'serviceName',
                    },
                    {
                        label: this.$t('table.description'),
                        field: 'serviceDescription',
                    },
                ]
                if(this.isTouristPolice){
                    returnData[0] =  {label: this.$t('table.incidents'),field:'incidentName'}
                }
                return returnData
            },
            packageColumns(){
                return [
                    {
                        label: this.$t('table.packages'),
                        field: 'packageName',
                    },
                    {
                        label: this.$t('table.description'),
                        field: 'packageDescription',
                    },
                ]
            },
            destinationColumns(){
                return [
                    {
                        label: this.$t('table.destinations'),
                        field: 'destination',
                    },
                ]
            },
            availableRoomColumns(){
                return [
                    {
                        label: this.$t('table.room_number'),
                        field: 'roomNumber',
                    },
                    {
                        label: this.$t('table.category'),
                        field: 'category',
                    },
                    {
                        label: this.$t('table.type'),
                        field: 'type',
                    },
                    {
                        label: this.$t('table.fare'),
                        field: 'fare',
                    },
                ]
            },
            sellingDataColumns(){
                return [
                    {
                        label: this.$t('table.currency_from_to'),
                        field: 'fromCurrency',
                    },
                    {
                        label: this.$t('table.selling_rates'),
                        field: 'sellingRate',
                    },
                ]
            },
            buyingDataColumns(){
                return [
                    {
                        label: this.$t('table.currency_from_to'),
                        field: 'fromCurrency',
                    },
                    {
                        label: this.$t('table.min_amount'),
                        field: 'minAmount',
                    },
                    {
                        label: this.$t('table.max_amount'),
                        field: 'maxAmount',
                    },
                    {
                        label: this.$t('table.buying_rates'),
                        field: 'buyingRate',
                    },
                ]
            },
            peakDataColumns(){
               return [

                    {
                        label: this.$t('table.mountain'),
                        field: 'mountainName',
                    },
                    {
                        label: this.$t('table.peak_height'),
                        field: 'peak',
                    },
                ]
            },
            academicDataColumns(){
                return [
                    {
                        label: this.$t('table.university_name'),
                        field:'universityName',
                    },
                    {
                        label: this.$t('table.academic_progress'),
                        field:'academicProgramme',
                    },
                ]
            },
            trainingDataColumns(){
                return [
                    {
                        label: this.$t('table.training'),
                        field:'training',
                    }
                ]
            },
            internshipDataColumns(){
                return [
                    {
                        label: this.$t('table.internship_type'),
                        field:'internshipType',
                    },
                    {
                        label: this.$t('table.name_of_institute'),
                        field:'nameOfInstitute',
                    },
                ]
            },
            touristPoliceDataColumns(){
                return [
                    {label:this.$t('table.incidents'),field:'incidentName'},
                    {label:this.$t('table.description'),field:'incidentDescription'},
                ]
            }
        },
    }
</script>

<style scoped lang="scss">
    $blue: #236aac;
    $blue1: #061c6d;
    $red: #B72626;
    $grey: #eeeeee51;
    $grey1: #eeeeee99;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background:#4e434361;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4e434361;
    }
</style>
