.3
<template>
    <v-card class="scroll-thin">
        <v-toolbar dark color="#236aac" class="darken-1">
            <v-toolbar-title>
                {{ divisionName }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <div v-if="isAirport" class=" pas-flex-container pt-5">
                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.first_name')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.airportName}}</div>
                       </div>
                    </div>
                    <div class="pas-flex-item">
                      <div class="">
                          <div class="label">
                              <strong>
                                  {{$t('table.airport_type')}}
                              </strong>

                          </div>
                          <div class="value">{{branchDetails.airportType}}</div>
                      </div>
                    </div>

                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.city')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.city}}</div>
                       </div>
                    </div>

                    <div class="pas-flex-item">
                        <div class="">
                            <div class="label">
                                <strong>
                                    {{$t('table.doe')}}
                                </strong>

                            </div>
                            <div class="value pa-1">{{branchDetails.establishedYear}}</div>
                        </div>
                    </div>

                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.iata')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.iataCode}}
                           </div>
                       </div>
                    </div>
                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.icao')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.icaoCode}}
                           </div>
                       </div>
                    </div>
                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.WAC')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.worldAreaCode}}
                           </div>
                       </div>
                    </div>
                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.length_of_runway')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.lengthOfRunway}}
                           </div>
                       </div>
                    </div>
                   <div class="pas-flex-item aboutus" v-if="branchDetails.aboutUs">
                     <div class="">
                         <div class="label">
                             <strong>
                                 {{$t('table.about_us')}}
                             </strong>

                         </div>
                         <div class="value">{{branchDetails.aboutUs}}

                         </div>
                     </div>
                   </div>
            </div>
            <div v-else class=" pas-flex-container pt-5">
                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.first_name')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.branchName}}</div>
                       </div>
                    </div>
                    <div class="pas-flex-item">
                      <div class="">
                          <div class="label">
                              <strong>
                                  {{$t('table.address')}}
                              </strong>

                          </div>
                          <div class="value">{{branchDetails.branchAddress}}</div>
                      </div>
                    </div>

                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('phone')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.branchPhoneNumber}}</div>
                       </div>
                    </div>

                    <div class="pas-flex-item">
                        <div class="">
                            <div class="label">
                                <strong>
                                    {{$t('email')}}
                                </strong>

                            </div>
                            <div class="value pa-1">{{branchDetails.branchEmail}}</div>
                        </div>
                    </div>

                    <div class="pas-flex-item">
                       <div class="">
                           <div class="label">
                               <strong>
                                   {{$t('table.website')}}
                               </strong>

                           </div>
                           <div class="value">{{branchDetails.branchWebsite}}
                           </div>
                       </div>
                    </div>
                   <div class="pas-flex-item aboutus" v-if="branchDetails.aboutUs">
                     <div class="">
                         <div class="label">
                             <strong>
                                 {{$t('table.about_us')}}
                             </strong>

                         </div>
                         <div class="value">{{branchDetails.aboutUs}}

                         </div>
                     </div>
                   </div>
            </div>
            <h3>{{$t('location')}}</h3>
            <l-map style="height: 350px" :zoom="zoom" :center="center" v-if="show">
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker :lat-lng="markerLatLng"></l-marker>
            </l-map>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('close')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from 'axios'
    import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
    import {Icon} from 'leaflet'

    delete Icon.Default.prototype._getIconUrl;

    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });


    export default {
        components: {LMap, LTileLayer, LMarker},
        name: "BranchDetails",
        props: [
            'branchID',
            'divisionName'
        ],
        data() {
            return {
                branchDetails: {},
                airportDetails:{},
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 14,
                center: [],
                markerLatLng: [],
                show: false
            }
        },
        methods: {
            dialogueClose() {
                this.$emit("dialogueClose");
            },
            loadAirportDetails(){
               this.branchDetails = {
                   airportCategory:'Domestic',
                   airportName:'Lukla',
                   airportType:'Seasonal',
                   establishedYear:'2020-06-02',
                   iataCode:'LKL',
                   icaoCode:'LKLICAO',
                   latitude:103,
                   longitude:103,
                   lengthOfRunway:'1111',
                   worldAreaCode:'WACLKL',
               }
                this.center = [27.688399, 85.335618]
                this.markerLatLng = this.center = [27.688399, 85.335618]
                this.show = true
            },
            async loadDetails() {
                await axios.get('Branch/GetBranchDetailsMapByBranchID',
                    {params: {BranchID: this.branchID}}).then(response => {
                    if (response.data != null) {
                        let lat = 0;
                        let lng = 0;
                        let latlng = [];
                        this.branchDetails = response.data;
                        if (this.branchDetails.latitude != "" && this.branchDetails.longitude != "" && this.branchDetails.latitude != null && this.branchDetails.longitude != null) {
                            lat = parseFloat(this.branchDetails.latitude);
                            lng = parseFloat(this.branchDetails.longitude);
                            latlng.push(lat);
                            latlng.push(lng);
                            this.center = latlng;
                            this.markerLatLng = latlng;
                        } else {
                            this.center = [27.688399, 85.335618]
                            this.markerLatLng = this.center = [27.688399, 85.335618]
                        }
                        this.show = true

                    }
                });


            },
        },
        mounted() {
            if(this.isAirport){
                this.loadAirportDetails()
            }
            else{
                this.loadDetails()
            }
        },
        computed: {
            isAirport(){
                return this.divisionName === "Airport"
            }
        },
    }
</script>

<style scoped lang="scss">
    $blue: #236aac;
    $blue1: #061c6d;
    $red: #B72626;
    $grey: #eeeeee51;
    $grey1: #eeeeee99;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background:#4e434361;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4e434361;
    }
.pas-flex-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:5px
}
    .pas-flex-item{
        box-shadow: 0 3px 5px 0 #eeeeee73;
        padding: 10px;
        flex-grow: 1;
        flex-basis: 200px;
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .aboutus{
        flex-basis: 100%;
    }

</style>