<template>
    <section class="charts">
                <vue-highcharts :key="'multiDataBarChart-'+keyChart" :options="options" :highcharts="Highcharts"></vue-highcharts>
           
    </section>
</template>
<script>
    import VueHighcharts from "vue2-highcharts";
    import Highcharts from "highcharts";

    export default {
        components: {
            VueHighcharts
        },
        props: {
            dataBarChart: Object,
            keyChart: Number
        },
        data() {
            return {
                options: {
                    spacingBottom: 15,
                    spacingTop: 10,
                    spacingLeft: 10,
                    spacingRight: 10,
                    chart: {
                        renderTo: "container",
                        type: "column",
                        height: "400rem",
                    },

                    subtitle: {
                        text: ""
                    },
                    xAxis: {},
                    yAxis: {
                        min: 0,
                        title: {
                            text: "",
                            align: "high"
                        },
                        labels: {
                            overflow: "justify"
                        }
                    },

                    plotOptions: {
                        column: {
                            depth: 25
                        }
                    },
                    series: [],
                    title:"Bar Chart"
                },
                Highcharts,
            };
        },
        created() {
            this.getBarChartData();
        },
        methods: {
            getBarChartData() {
                this.options.title = this.dataBarChart.title || "Bar Chart"
                this.options.xAxis = this.dataBarChart.xAxisData || {}
                this.options.series = this.dataBarChart.data
            },
        }
    };
</script>
<style>
    .highcharts-credits {
        display: none;
    }
   
</style>
