<template>
    <v-card>
        <v-toolbar dark color="#236aac">
            <v-toolbar-title>
                {{$t('navBarLogin.reports')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-chip class="ma-2"
                    color="rgb(157, 25, 25)"
                    label
                    text-color="white"
                    v-if="snackbar.report"
                    @click="backToMenu">
                <v-icon left>mdi-label</v-icon>
                {{$t('navBarLogin.reports')}}
            </v-chip>
            <div class="reportTable" v-if="snackbar.report">
                <component :is="componentName"></component>
            </div>
            <v-row class="pt-5" v-else>

                    <v-col
                           v-for="title in titles"
                           v-bind:key="title.link"
                           class="viewCard col-12 col-sm-6 col-md-4 col-lg-3" @click="loadReports(title)">
                            <HoverCard :cardData="title"></HoverCard>
                        </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('close')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "ReportMenu",
        props: [
            'branchID',
            'divisionName'
        ],
        data() {
            return {
                titles: [
                    {
                        name: this.$t('modules_name.tourist_arrival'),
                        component: "ImmigrationReportMenu",
                        icon: "mdi-account-arrow-left-outline"
                    },
                    {
                        name: this.$t('modules_name.tourist_police'),
                        component: "TouristPoliceReportTable",
                        icon: "mdi-account-cowboy-hat-outline"
                    },
                    {
                        name: this.$t('modules_name.tourist_vehicle'),
                        component: "TouristVehicleReportTable",
                        icon: "mdi-car-convertible"
                    },
                    {
                        name: this.$t('dot'),
                        component: "DotReportMenu",
                        icon: "mdi-store"
                    },
                    {
                        name: this.$t('modules_name.mountaineering'),
                        component: "MountainReportTable",
                        icon: "mdi-image-filter-hdr"
                    },
                    {
                        name: this.$t('modules_name.hotel'),
                        component: "HotelReportTable",
                        icon: "mdi-bed-outline"
                    },
                    {
                        name: this.$t('modules_name.home_stay'),
                        component: "HomestayReportTable",
                        icon: "mdi-home-silo"
                    },
                    {
                        name: this.$t('modules_name.income_generation'),
                        component: "IncomeGenerationReportTable",
                        icon: "mdi-cash-multiple"
                    },
                    {
                        name: this.$t('modules_name.central_bureau'),
                        component: "CentralBureauReportTable",
                        icon: "mdi-account-group"
                    },
                    {
                        name: this.$t('modules_name.national_park'),
                        component: "NationalParkReportTable",
                        icon: "mdi-car-brake-parking"
                    },
                    {
                        name: this.$t('modules_name.pilgrimage'),
                        component: "PilgrimageReportTable",
                        icon: "mdi-temple-buddhist-outline"
                    },
                    {
                        name: this.$t('modules_name.trekkingAll'),
                        component: "TrekkingReportMenu",
                        icon: "mdi-walk"
                    },
                    //{
                    //    name: this.$t('modules_name.trekking'),
                    //    component: "TrekkingReportTable",
                    //    icon: "mdi-walk"
                    //},
                    //{
                    //    name: this.$t('modules_name.trekkingRestrictedAreas'),
                    //    component: "TrekkingRestrictedAreasReportTable",
                    //    icon: "mdi-walk"
                    //},                
                    //{
                    //    name: this.$t('modules_name.trekkingRestrictedAreasProcessed'),
                    //    component: "TrekkingRestrictedAreasProcessedReportTable",
                    //    icon: "mdi-walk"
                    //},
                    {
                        name: this.$t('modules_name.aviation'),
                        component: "AviationReportMenu",
                        icon: "mdi-airplane"
                    },
                    {
                        name: this.$t('modules_name.academic'),
                        component: "AcademicReportMenu",
                        icon: "mdi-book-open-page-variant"
                    },
                   
                    {
                        name: this.$t('modules_name.airports'),
                        component: "AirportReportMenu",
                        icon: "mdi-airport"
                    },
                   
                    {
                        name: this.$t('nma'),
                        component: "NmaReportTable",
                        icon: "mdi-image-filter-hdr"
                    }               
                   
                ],
                componentName: '',
                snackbar: {
                    report: false,
                }
            }
        },
        methods: {
            loadReports({ component }) {
                console.log(component)
                this.componentName = component
                this.snackbar.report = true
            },
            backToMenu() {
                this.componentName = ''
                this.snackbar.report = false
            },
            dialogueClose() {
                this.$emit('dialogueClose')
            }
        },
        mounted() {


        },
        computed: {},
        components: {
            HoverCard,
            NationalParkReportTable: () => import(/* webpackChunkName: "NationalParkReportTable" */ './NationalParkReportTable'),
            TouristPoliceReportTable: () => import(/* webpackChunkName: "TouristPoliceReportTable" */ './TouristPoliceReportTable'),
            PilgrimageReportTable: () => import(/* webpackChunkName: "PilgrimageReportTable" */ './PilgrimageReportTable'),
            HotelReportTable: () => import(/* webpackChunkName: "HotelReportTable" */ './HotelReportTable'),
            TrekkingReportMenu: () => import(/* webpackChunkName: "TrekkingReportMenu" */ './TrekkingReportMenu'),
            //TrekkingReportTable: () => import(/* webpackChunkName: "TrekkingReportTable" */ './TrekkingReportTable'),
            //TrekkingRestrictedAreasReportTable: () => import(/* webpackChunkName: "TrekkingReportTable" */ './TrekkingRestrictedAreasReportTable'),
            //TrekkingRestrictedAreasProcessedReportTable: () => import(/* webpackChunkName: "TrekkingReportTable" */ './TrekkingRestrictedAreasProcessedReportTable'),
            ImmigrationReportTable: () => import(/* webpackChunkName: "ImmigrationReportTable" */ './NationalityReportTable'),
            MountainReportTable: () => import(/* webpackChunkName: "MountainReportTable" */ './MountainReportTable'),
            AcademicReportTable: () => import(/* webpackChunkName: "AcademicReportTable" */ './AcademicReportTable'),
            IncomeGenerationReportTable: () => import(/* webpackChunkName: "IncomeGenerationReportTable" */ './IncomeGenerationReportTable'),
            HomestayReportTable: () => import(/* webpackChunkName: "HomestayReportTable" */ './HomestayReportTable'),
            ImmigrationReportMenu: () => import(/* webpackChunkName: "ImmigrationReportMenu" */ './ImmigrationReportMenu'),
            AcademicReportMenu: () => import(/* webpackChunkName: "AcademicReportMenu" */ './AcademicReportMenu'),
            AirportReportMenu: () => import(/* webpackChunkName: "AirportReportMenu" */ './AirportReportMenu'),
            AviationReportMenu: () => import(/* webpackChunkName: "AviationReportMenu" */ './AviationReportMenu'),
            DotReportMenu: () => import(/* webpackChunkName: "AviationReportMenu" */ './DotReportMenu'),
            NrbReportTable: () => import(/* webpackChunkName: "AviationReportMenu" */ './NrbReportTable'),
            CentralBureauReportTable: () => import(/* webpackChunkName: "AviationReportMenu" */ './CentralBureauReportTable'),
            NmaReportTable: () => import(/* webpackChunkName: "AviationReportMenu" */ './NmaReportTable'),
            TouristVehicleReportTable: () => import(/* webpackChunkName: "AviationReportMenu" */ './TouristVehicleReportTable'),
        }
    }
</script>

<style scoped lang="scss">
    $blue: #236aac;
    $blue1: #061c6d;
    $red: #B72626;
    $grey: #eeeeee51;
    $grey1: #eeeeee99;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4e434361;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4e434361;
    }

    .pas-flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 5px
    }
    @media screen and (max-width: 1000px) {
        .pas-flex-container {
            width: 50%;
            display: block;
            margin-bottom: 20px;
        }
        .viewCard {
            width: 100% !important;
        }
    }

    @media screen and (max-width: 600px) {
        .pas-flex-container {
            width: 100% !important;
            display: block;
            margin-bottom: 20px;
        }

        .viewCard {
            width: 100% !important;
        }
    }
    .pas-flex-item {
        box-shadow: 0 3px 5px 0 #eeeeee93;
        padding: 10px;
        flex-grow: 1;
        flex-basis: 200px;
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    @media screen and (max-width: 600px) {
        .viewCard {
            width: 200%;
        }
    }
    @media only screen and (max-width: 600px) and (min-width: 400px) {
        .viewCard {
            width: 200%;
        }
    }
</style>