<template>
    <v-card class="scroll-thin">
        <v-toolbar dark color="#236aac" class="darken-1">
            <v-toolbar-title>
                {{$t('inquiry_form')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                           :error-messages="nameErrors"
                            required
                            @input="$v.formData.name.$touch()"
                            @blur="$v.formData.name.$touch()"
                         :label="$t('legal_name')" v-model="formData.name" ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field 
                         :error-messages="addressErrors"
                            required
                            @input="$v.formData.address.$touch()"
                            @blur="$v.formData.address.$touch()"
                        :label="$t('address')" v-model="formData.address"></v-text-field>
                    </v-col>
                    <v-col cols="12"  md="6">
                        <v-text-field 
                         :error-messages="emailErrors"
                            required
                            @input="$v.formData.email.$touch()"
                            @blur="$v.formData.email.$touch()"
                        :label="$t('email*')" v-model="formData.email" ></v-text-field>
                    </v-col>
                    <v-col cols="12"  md="6">
                        <v-text-field 
                         :error-messages="contactNumberErrors"
                            required
                            @input="$v.formData.contactNumber.$touch()"
                            @blur="$v.formData.contactNumber.$touch()"
                        :label="$t('contact*')" v-model="formData.contactNumber" ></v-text-field>
                    </v-col>
                    <v-col cols="12" >
                        <v-textarea
                         :error-messages="messageErrors"
                            required
                            @input="$v.formData.message.$touch()"
                            @blur="$v.formData.message.$touch()"
                                filled
                                auto-grow
                                :label="$t('message')"
                                rows="4"
                                row-height="30"
                                v-model="formData.message"
                                shaped
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
            <small>{{$t('indicates_required_field')}}</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('close')}}</v-btn>
            <v-btn @click="formSubmit" color="blue darken-1" dark>{{$t('submit_inquiry')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "InquiryForm",
            data() {
            return {
                popup:false,
                submitStatus:"",
                formData:{
                
                },
                pendingUserList: [
                    {
                        branchName: "Lukla",
                        divisionName: "Airport"
                    },
                    {
                        branchName: "OYO",
                        divisionName: "Hotel"
                    },
                    {
                        branchName: "Solo Adventures",
                        divisionName: "Adventure"
                    },
                    {
                        branchName: "Narayanhiti Museum",
                        divisionName: "Museum"
                    },
                ],
                pendingUserColumns: [
                    {
                        label: 'Division',
                        field: 'divisionName',
                    },
                    {
                        label: 'Branch',
                        field: 'branchName',
                    },
                ]
            }
        },
        methods: {
            dialogueClose() {
                this.$emit("dialogueClose",this.popup);
            },
            formSubmit(){

                 this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {

                    axios.post('Shared/SendInquiryAsync', this.formData).then(res => {
                        console.log(res);
                    this.popup = true
                    this.$emit("dialogueClose",this.popup);
                });
                }
            }
        },

        computed:{
                nameErrors() {
                                const errors = [];
                                if (!this.$v.formData.name.$dirty) return errors;
                                !this.$v.formData.name.required &&
                                errors.push("name is required.");
                                return errors;
                            },
                addressErrors() {
                                const errors = [];
                                if (!this.$v.formData.address.$dirty) return errors;
                                !this.$v.formData.address.required &&
                                errors.push("address is required.");
                                return errors;
                            },
                emailErrors() {
                                const errors = [];
                                if (!this.$v.formData.email.$dirty) return errors;
                                !this.$v.formData.email.required &&
                                errors.push("email is required.");
                                return errors;
                            },
                contactNumberErrors() {
                                const errors = [];
                                if (!this.$v.formData.contactNumber.$dirty) return errors;
                                !this.$v.formData.contactNumber.required &&
                                errors.push("contactNumber is required.");
                                return errors;
                            },
                messageErrors() {
                                const errors = [];
                                if (!this.$v.formData.message.$dirty) return errors;
                                !this.$v.formData.message.required &&
                                errors.push("message is required.");
                                return errors;
                            },
        },
        validations: {
            formData: {
                name: {required},
                address: {required},
                email: {required},
                contactNumber: {required},
                message: {required},
            }
        },
    }
</script>

<style scoped lang="scss">
    $blue: #236aac;
    $blue1: #061c6d;
    $red: #B72626;
    $grey: #eeeeee51;
    $grey1: #eeeeee99;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $grey1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background:#4e434361;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4e434361;
    }
</style>