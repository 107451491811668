<template>
    <v-row no-gutters>
        <v-col class="ticker_title text-center"
                cols="2"
               sm="1" style="background-color: rgb(157, 25, 25)"
        >
            {{$t('notice')}}
        </v-col>
        <v-col class="ticker_container"
                cols="10"
               sm="11"
        >
            <marquee-text :duration="30">
                <span v-for="notice in newsTickerData" :key="notice">
                {{notice.value}}
                </span>
            </marquee-text>
        </v-col>
    </v-row>
</template>

<script>
    import MarqueeText from "vue-marquee-text-component";
    export default {
        name: "NewsTicker",
        props: {
            source: String,
            newsTickerData: Array
        },
        components: {
            MarqueeText
        },
        data: () => ({
            drawer: null,
            notices:[],
            timestamp: ""
        }),
        computed: {},
        created() {
            this.getNotice();
        },
        methods: {
            async getNotice() {
              
            }
        }
    };
</script>
<style scoped lang="scss">
    .item-center {
        display: grid;
        align-items: center;
        justify-content: center;
    }

    .item-vertical-center {
        display: grid;
        align-items: center;
    }

    .full-div {
        width: 100%;
        height: 100%;
    }

    .mapdiv {
        path {
            fill: #dece0c;
            stroke: #cc1100;
            transition: fill 0.3s;
        }
    }

    .module {
        border: 1px solid #eee;
    }
    .ticker_title{
        background-color: #B72626;
        color: white;
    }
    .ticker_container{
        background-color: antiquewhite;
        color: black;
    }
</style>
