<template>
    <section class="charts">
                <vue-highcharts :options="pieOptions" :key="'piechart-'+keyChart"></vue-highcharts>
    </section>
</template>
<script>
    import VueHighcharts from "vue2-highcharts";
    import Highcharts3D from "highcharts/highcharts-3d";
    import Highcharts from "highcharts";

    Highcharts3D(Highcharts);

    export default {
        components: {
            VueHighcharts
        },
        props: {
            dataBarChart: Object,
            keyChart: Number

        },
        data() {
            return {
                Highcharts,
                pieOptions: {
                    chart: {
                        type: "pie",
                        options3d: {
                            enabled: true,
                            alpha: 45
                        },
                        height: "400rem",
                    },
                    title: {
                        text: ""
                    },
                    subtitle: {
                        text: ""
                    },
                    plotOptions: {
                        pie: {
                            innerSize: 5,
                            depth: 45,
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<h3><b>{point.name}</b> : {point.percentage:.1f} %</h3>'
                            }
                        }
                    },
                    series: [
                        {
                            name: "Arrival",
                            data: [

                            ]
                        }
                    ],
                },

            };
        },
        created() {
           this.getPieChartData();
        },
        methods: {
            getPieChartData() {
                this.pieOptions.title = this.dataBarChart.title
                this.pieOptions.series= [
                    {
                        name: "Delivered amount",
                        data: this.dataBarChart.pieData

                    }
                ]
            }
        }
    };
</script>
<style>
    .highcharts-credits {
        display: none;
    }

</style>
